import { useState } from 'react';
import { Button, Form, Row, Col, Card, Alert, ListGroup, ListGroupItem, } from 'react-bootstrap';
import { useContext } from 'react';
import UserContext from '../../../context/userContext.js';
import { submitMMRConsent, generatePDF } from '../../../utils/sendRequest.js';
import { pdfReferenceValues } from '../../../utils/pdfReferenceValues.js';
import * as FormValidator from '../../../validation/index.js';
import { dateConversion } from '../../../utils/dateConversion.js';
import Demographics from './subForms/demographics.js';
import moment from 'moment';
import Wizard from './wizardForm.js';
import DuplicateRecord from './subForms/duplicateRecord.js';
import MmrQuestions from './subForms/mmrQuestions.js';
import ParentalResponsibility from './subForms/parentalResponsibility.js';

export default function MMR(props) {
  const userDetail = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const WizardStep = ({ children }) => children;
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [serverError, setServerError] = useState();
  const [pdfFormValues, setPdfFormValues] = useState();

  const filterRelationships = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'PARELAT',
  );

  const filterGender = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'GENDER',
  );

  const filterYearGroup = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'SCHYRSGP',
  );

  const filterRefusal = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'PAREFUSAL',
  );

  const pdfDownload = async () => {
    const pdf = pdfReferenceValues(
      pdfFormValues,
      props.ethnicityOptions,
      filterGender,
      filterYearGroup,
      filterRefusal,
      filterRelationships,
    );

    setProcessing(true);

    try {
      const resPdf = await generatePDF(
        pdf,
        userDetail.token,
        props.vaccineQuestions,
      );

      if (!resPdf) {
        setFailed();
      }

      const file = new Blob([resPdf], { type: 'application/pdf' });
      //IE fix
      // window navigator - detect browser
      //msSaveOrOpenBlob ie method to save a file in a browser
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
        return;
      }

      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `Imm Consent Form ${pdfFormValues.inputDate}.pdf`;
      link.click();
      setTimeout(() => {
        URL.revokeObjectURL(link);
        setProcessing(false);
        setFailed(null);
      }, 100);
    } catch (error) {
      setProcessing(false);
      setFailed(error);
    }
  };

  if (success) {
    return (
      <Row>
        <Col xs={12} sm={12} md={8}>
          {failed && (
            <Alert variant="danger">Error downloading Consent Form PDF</Alert>
          )}
          <Alert variant="success">
          Consent form successfully submitted. Thank you. <br/>
          Please click on the above logout button to end your session and to complete another form.
          </Alert>
          <Button className="mt-3" onClick={pdfDownload} disabled={processing}>
            Download Consent Form PDF
          </Button>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col xs={12} sm={12} md={8}>
          <Card>
            <Card.Header className="bg-light h4">{`MMR – Measles/Mumps and Rubella Consent form`}</Card.Header>
            {failed && (
              <Card bg="danger" text="light" className="mb-3">
                <Card.Header>Error</Card.Header>
                <Card.Body>
                  {serverError
                    ? serverError.message
                    : 'Error updating patient form record'}
                </Card.Body>
              </Card>
            )}
            </Card>
              <Wizard
                initialValues={{
                  //Patient
                  patientForename: '',
                  patientSurname: '',
                  nHSNumber: '',
                  patientGender: '',
                  patientGenderOther: '',
                  dobMonth: '',
                  dobYear: '',
                  dobDay: '',
                  patientDOB: '',
                  patientAddressLine1: '',
                  patientAddressLine2: '',
                  patientAddressLine3: '',
                  patientAddressLine4: '',
                  patientPostCode: '',
                  childPostCodeSearch: '',
                  parentGuardianConsentPhoneCarer: '',
                  parentGuardianConsentEmailAddress: '',
                  parentGuardianConsentRelationshipForChild: '',
                  parentGuardianConsentRelationshipForChildOther: '',
                  ethnicity: '',
                  yearGroup: '',
                  yearClass: '',

                  //Consent

                  parentGuardianConsentStatus: '',
                  parentGuardianConsentReasonForRefusal: '',
                  parentGuardianConsentReasonForRefusalComments: '',
                  parentGuardianConsentSignatureName: '',
                  parentGuardianConsentSignatureDate: '',
                  parentGuardianConsentSignatureDay: moment().date(),
                  parentGuardianConsentSignatureMonth: 1 + moment().month(),
                  parentGuardianConsentSignatureYear: moment().year(),
                  parentGuardianNeedMoreInfoRequire: '',
                  parentGuardianFurtherInformation: '',
                  q1: '',
                  q1Comment: null,
                  q1VaccineWhere: '',
                  q1VaccineBrand: null,
                  q1VaccineWhen: null,
                  q1VaccineWhenDay: '',
                  q1VaccineWhenMonth: '',
                  q1VaccineWhenYear: '',
                  q2: '',
                  q2Comment: '',
                  q3: '',
                  q3Comment: '',
                  q4: '',
                  q4Comment: '',
                  q5: '',
                  q5Comment: '',
                  q6: '',
                  q6Comment: '',
                  q7: null,
                  q7Comment: null,
                  q8: null,
                  q8Comment: null,
                  q9: null,
                  q9Comment: null,
                }}
                validationSchema={FormValidator.MMR}
                onSubmit={async (values) => {
                  values.vaccineDeliveredCode = userDetail.vaccineCode;
                  values.consentLetterReferenceCode =
                    userDetail.consentLetterReferenceCode;
                  values.schoolCodeURN = userDetail.schoolCode;

                  values.patientDOB = dateConversion(
                    values.dobYear,
                    values.dobMonth,
                    values.dobDay,
                  );

                  values.parentGuardianConsentSignatureDate = dateConversion(
                    values.parentGuardianConsentSignatureYear,
                    values.parentGuardianConsentSignatureMonth,
                    values.parentGuardianConsentSignatureDay,
                  );

                  //Day, month, year are nullable - make sure have all values before date conversion
                  if (
                    values.q1VaccineWhenYear &&
                    values.q1VaccineWhenMonth &&
                    values.q1VaccineWhenDay
                  ) {
                    values.q1VaccineWhen = dateConversion(
                      values.q1VaccineWhenYear,
                      values.q1VaccineWhenMonth,
                      values.q1VaccineWhenDay,
                    );
                  }

                  setLoading(true);

                  try {
                    const submitConsentFom = await submitMMRConsent(
                      userDetail.token,
                      values,
                    );

                    setLoading(false);
                    setSuccess(true);
                    setFailed(null);
                    setServerError(null);
                    values['inputDate'] = moment(submitConsentFom) // returns the date of input
                      .utc()
                      .format('DD-MM-YYYY HH:mm:ss'); //PDF timestamp
                    setPdfFormValues(values);
                  } catch (error) {
                    setLoading(false);
                    setFailed(true);
                    setServerError(error);
                  }
                }}
              >
                  <WizardStep validationSchema={FormValidator.demographics}>
                    <Card.Body>
              <Card.Text>
               The NHS recommends that all children have two MMR vaccinations.
               These offer protection against measles, mumps, and rubella. They are routinely given by your child’s GP at the age of 1 year and just before they start schoo
              </Card.Text>
              <Card.Text>
              Childhood vaccinations are recorded on the local child health information system (CHIS). Your child does not have two MMR vaccinations recorded on this system
              </Card.Text>
              <Card.Text>
              It may be that the MMR vaccination(s) your child received have not been reported to CHIS.
              </Card.Text>
              <Card.Text>
              If you think your child has received two MMR vaccinations, please check with your GP and let us know the dates when your child had the MMR vaccines.
              </Card.Text>
              <Card.Text>
              We can then update your child’s record with the dates of MMR vaccine received
              </Card.Text>
              <Card.Title>
              Information about the MMR-Measles/Mumps and Rubella vaccine
              </Card.Title>
              <Card.Text>
              MMR vaccine offers protection against three infectious diseases.
              </Card.Text>
              <Card.Text>
              <b>Measles - </b>Measles is a very infectious viral illness that is spread by coughs and sneezes. 
              Complications can include chest and ear infections, fits, diarrhoea, encephalitis (infection of the brain) and brain damage. 
              Those who develop complications may need to be admitted to hospital for treatment.
              </Card.Text>
              <Card.Text>
              <b>Mumps - </b>Mumps is a viral illness that is spread by coughs and sneezes or close contact with someone who already has the infection. 
              Complications of mumps can be very painful and can include inflammation of the ovaries or testicles, and in rarer cases, the pancreas. 
              Mumps can also cause viral meningitis and encephalitis (infection of the brain).
              </Card.Text>
              <Card.Text>
              <b>Rubella - </b>Rubella is a viral illness, often called German measles, that is now rare in the UK thanks to the success of the MMR vaccine. 
              Complications of rubella are rare but if a pregnant woman catches rubella during pregnancy, there can be devastating consequences for her unborn baby which could lead 
              to the baby being born with cataracts (eye problems), deafness, heart problems or brain damage.
              </Card.Text>
              <Card.Text>
                <b>The MMR vaccine we use does not contain gelatine</b>
              </Card.Text>
              <Card.Title>Common Side Effects</Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item>Redness/swelling at the injection site, and some people may get a small lump which will usually disappear in a few weeks.</ListGroup.Item>
                <ListGroup.Item>Raised temperature</ListGroup.Item>
                <ListGroupItem>Headache</ListGroupItem>
                <ListGroup.Item>Dizziness</ListGroup.Item>
                <ListGroup.Item>
                  Feeling sick with swollen glands
                </ListGroup.Item>
                <ListGroup.Item>
                More serious side effects are extremely rare and the nurses are trained to deal with these.
              </ListGroup.Item>
              </ListGroup>
              <Card.Text>
              For more information about the MMR vaccination please visit
              </Card.Text>
              <Card.Text>
              <a
                  href="https://www.gov.uk/government/publications/mmr-for-all-general-leaflet"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.gov.uk/government/publications/mmr-for-all-general-leaflet
                </a>
              </Card.Text>
              <Card.Text>
              Children under the age of 16 can consent to their own treatment if they're believed to have enough intelligence, 
              competence and understanding to fully appreciate what's involved in their treatment. 
              This is known as being Gillick competent.
              </Card.Text>
              </Card.Body>
                    <Demographics
                      loading={loading}
                      gender={filterGender}
                      YearGroup={filterYearGroup}
                      ethnicity={props.ethnicityOptions}
                    />
                </WizardStep> 
                 <WizardStep >
                    <DuplicateRecord/>
                </WizardStep>
                <WizardStep validationSchema={FormValidator.MMR}>
                  <ParentalResponsibility/>
                  <MmrQuestions
                  loading={loading}
                  filterRefusal={filterRefusal}
                  filterRelationships={filterRelationships}
                  vaccineQuestions={props.vaccineQuestions}
                  />
                </WizardStep>
              </Wizard>
              </Col>
              </Row>
            );
    }
}
