import { useState } from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  Card,
  Alert,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';
import { useContext } from 'react';
import UserContext from '../../../context/userContext.js';
import Wizard from './wizardForm.js';
import DuplicateRecord from './subForms/duplicateRecord.js';
import MenACWYTdIPVQuestions from './subForms/MenACWYTdIPVQuestions.js';
import {
  submitConsentMenACWYTdIPV,
  generatePDFMen,
} from '../../../utils/sendRequest.js';
import { pdfReferenceValuesMenACWYTdIPV } from '../../../utils/pdfReferenceValues.js';
import * as FormValidator from '../../../validation/index.js';
import { dateConversion } from '../../../utils/dateConversion.js';
import Demographics from './subForms/demographics.js';
import moment from 'moment';

export default function MenACWYTdIPV(props) {
  const userDetail = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const WizardStep = ({ children }) => children;
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [serverError, setServerError] = useState();
  const [processing, setProcessing] = useState(false);

  const [pdfFormValues, setPdfFormValues] = useState();

  const filterRelationships = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'PARELAT',
  );

  const filterGender = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'GENDER',
  );

  const filterYearGroup = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'SCHYRSGP',
  );

  const filterRefusal = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'PAREFUSAL' && x.CarePlusCoding !== 'VC', //Filter out gelatine option
  );

  const pdfDownload = async () => {
    const pdf = pdfReferenceValuesMenACWYTdIPV(
      pdfFormValues,
      props.ethnicityOptions,
      filterGender,
      filterYearGroup,
      filterRefusal,
      filterRelationships,
    );

    setProcessing(true);

    try {
      const resPdf = await generatePDFMen(
        pdf,
        userDetail.token,
        props.vaccineQuestions,
      );

      if (!resPdf) {
        setFailed();
      }

      const file = new Blob([resPdf], { type: 'application/pdf' });
      //IE fix
      // window navigator - detect browser
      //msSaveOrOpenBlob ie method to save a file in a browser
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
        return;
      }

      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `Imm Consent Form ${pdfFormValues.inputDate}.pdf`;
      link.click();
      setTimeout(() => {
        URL.revokeObjectURL(link);
        setProcessing(false);
        setFailed(null);
      }, 100);
    } catch (error) {
      setProcessing(false);
      setFailed(error);
    }
  };

  if (success) {
    return (
      <Row>
        <Col xs={12} sm={12} md={8}>
          {failed && (
            <Alert variant="danger">Error downloading Consent Form PDF</Alert>
          )}
          <Alert variant="success">
          Consent form successfully submitted. Thank you. <br/>
          Please click on the above logout button to end your session and to complete another form.
          </Alert>
          <Button className="mt-3" onClick={pdfDownload} disabled={processing}>
            Download Consent Form PDF
          </Button>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col xs={12} sm={12} md={8}>
          <Card>
            <Card.Header className="bg-light h4">{`${props.vaccineQuestions.title} consent form`}</Card.Header>
            {failed && (
              <Card bg="danger" text="light" className="mb-3">
                <Card.Header>Error</Card.Header>
                <Card.Body>
                  {serverError
                    ? serverError.message
                    : 'Error updating patient form record'}
                </Card.Body>
              </Card>
            )}
            </Card>
              <Wizard
                initialValues={{
                  //Patient
                  patientForename: '',
                  patientSurname: '',
                  nHSNumber: '',
                  patientGender: '',
                  patientGenderOther: '',
                  dobMonth: '',
                  dobYear: '',
                  dobDay: '',
                  patientDOB: '',
                  patientAddressLine1: '',
                  patientAddressLine2: '',
                  patientAddressLine3: '',
                  patientAddressLine4: '',
                  patientPostCode: '',
                  childPostCodeSearch: '',
                  parentGuardianConsentPhoneCarer: '',
                  parentGuardianConsentEmailAddress: '',
                  parentGuardianConsentRelationshipForChild: '',
                  parentGuardianConsentRelationshipForChildOther: '',
                  ethnicity: '',
                  yearGroup: '',
                  yearClass: '',

                  //Consent

                  parentGuardianConsentStatus: '',
                  parentGuardianConsentReasonForRefusal: '',
                  parentGuardianConsentReasonForRefusalComments: '',
                  parentGuardianConsentSignatureName: '',
                  parentGuardianConsentSignatureDate: '',
                  parentGuardianConsentSignatureDay: moment().date(),
                  parentGuardianConsentSignatureMonth: 1 + moment().month(),
                  parentGuardianConsentSignatureYear: moment().year(),
                  parentGuardianNeedMoreInfoRequire: '',

                  parentGuardianConsentStatusMenACWY: '',
                  parentGuardianConsentReasonForRefusalMenACWY: '',
                  parentGuardianConsentReasonForRefusalCommentsMenACWY: '',
                  parentGuardianConsentSignatureNameMenACWY: '',
                  parentGuardianConsentSignatureDateMenACWY: '',
                  parentGuardianConsentSignatureDateMenACWYDay: moment().date(),
                  parentGuardianConsentSignatureDateMenACWYMonth: 1 + moment().month(),
                  parentGuardianConsentSignatureDateMenACWYYear: moment().year(),
                  parentGuardianNeedMoreInfoRequireMenACWY: '',
                  parentGuardianConsentRelationshipForChildMenACWY: '',
                  parentGuardianConsentRelationshipForChildMenACWYOther: '',

                  parentGuardianFurtherInformation: '',
                  q1: '',
                  q1Comment: '',
                  q2: '',
                  q2Comment: '',
                  q3: '',
                  q3Comment: '',
                  q4: '',
                  q4Comment: '',
                  q5: '',
                  q5Comment: '',
                  q6: '',
                  q6Comment: '',
                }}
                validationSchema={FormValidator.MenACWYTdIPV}
                onSubmit={async (values) => {
                  values.vaccineDeliveredCode = userDetail.vaccineCode;
                  values.consentLetterReferenceCode =
                    userDetail.consentLetterReferenceCode;
                  values.schoolCodeURN = userDetail.schoolCode;

                  values.patientDOB = dateConversion(
                    values.dobYear,
                    values.dobMonth,
                    values.dobDay,
                  );

                  values.parentGuardianConsentSignatureDate = dateConversion(
                    values.parentGuardianConsentSignatureYear,
                    values.parentGuardianConsentSignatureMonth,
                    values.parentGuardianConsentSignatureDay,
                  );

                  values.parentGuardianConsentSignatureDateMenACWY =
                    dateConversion(
                      values.parentGuardianConsentSignatureDateMenACWYYear,
                      values.parentGuardianConsentSignatureDateMenACWYMonth,
                      values.parentGuardianConsentSignatureDateMenACWYDay,
                    );

                  setLoading(true);

                  try {
                    const submitConsentFom = await submitConsentMenACWYTdIPV(
                      userDetail.token,
                      values,
                    );

                    setLoading(false);
                    setSuccess(true);
                    setFailed(null);
                    setServerError(null);
                    values['inputDate'] = moment(submitConsentFom) // returns the date of input
                      .utc()
                      .format('DD-MM-YYYY HH:mm:ss'); //PDF timestamp
                    setPdfFormValues(values);
                  } catch (error) {
                    setLoading(false);
                    setFailed(true);
                    setServerError(error);
                  }
                }}
              >
                  <WizardStep validationSchema={FormValidator.demographics}>
                  <Card.Body>
              {/* <Card.Text>
                Please complete this consent form as soon as possible the web
                form will not be active 3 days before the session in school.
                This will allow us to clinically assess all requests for
                vaccination.
              </Card.Text> */}
              <Card.Text>
              Your child is due to have two booster vaccinations. One protects against diphtheria, tetanus, and polio. The other against meningitis and septicaemia.
              </Card.Text>
              <Card.Text>
              The School Aged Immunisation Service (SAIS) offers these vaccines in school when children are in year 9, or in year 10 if missed in year 9 and we have a consent form from you.
              </Card.Text>
              <Card.Text>
              If you change your mind at any point after you have sent your consent form, please contact the immunisation team
              </Card.Text>
              <Card.Text>
              Immunisation Team - Telephone - 0121 466 341<br/>
              Email - <a href='mailto:BCHNT.BirminghamImms@nhs.net'>BCHNT.BirminghamImms@nhs.net</a>
              </Card.Text>
              <Card.Text>
              <b>Please see below for more information about the teenage booster vaccination or visit:</b>
              </Card.Text>
              <Card.Text>
              <a href="https://www.gov.uk/government/publications/immunisations-for-young-people" target="_blank">https://www.gov.uk/government/publications/immunisations-for-young-people</a>
              </Card.Text>
              <Card.Text>
                <b><u>Information about the teenage booster vaccinations</u></b>
              </Card.Text>
              <Card.Title className="mb-3 text-success">
              Vaccine 1 (Td/IPV) offers protection against three things:
              </Card.Title>
              <Card.Text>
              <b>Diphtheria </b>is a serious disease that usually begins with a sore throat. It can cause breathing problems and damage the heart and nervous system. In severe cases, it can kill.
              </Card.Text>
              <Card.Text>
              <b>Tetanus</b> is a painful disease affecting the nervous system. It can lead to muscle spasms, may cause breathing problems and can kill. 
              Tetanus is caused by germs found in the soil and manure that get into your body through open cuts or burns.
              </Card.Text>
              <Card.Title></Card.Title>
              <Card.Text>
              <b>Polio</b> is a virus that attacks the nervous system. It can cause permanent paralysis of muscles. If it affects the chest muscles or the brain, polio can also kill.
              </Card.Text>
              <Card.Title className="mb-3 text-primary">
              Vaccine 2 (Men ACWY) offers protection against most meningitis and septicaemia.
              </Card.Title>
              <Card.Text>
              <b>Meningitis</b> affects the lining of the brain. It can be caused by viruses, bacteria, or other disease-causing organisms. Meningitis can also occur after an injury.
              </Card.Text>
              <Card.Text>
              <b>Septicaemia</b> is blood poisoning. It is very serious, especially if not diagnosed early, and can lead to death.
              </Card.Text>
              <Card.Text>
                <b>These vaccines do not contain gelatine</b>
              </Card.Text>
              <Card.Title>Common Side Effects</Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item>Redness/swelling at the injection site, and some people may get a small lump which will usually disappear in a few weeks.</ListGroup.Item>
                <ListGroup.Item>Raised temperature</ListGroup.Item>
                <ListGroupItem>Headache</ListGroupItem>
                <ListGroup.Item>Dizziness</ListGroup.Item>
                <ListGroup.Item>
                  Feeling sick with swollen glands
                </ListGroup.Item>
                <ListGroup.Item>
                More serious side effects are extremely rare and the nurses and
                trained to deal with these.
              </ListGroup.Item>
              </ListGroup>
             <br/>

              {/* <Card.Text>
                {props.vaccineQuestions.heading}{' '}
                <a
                  href={props.vaccineQuestions.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {props.vaccineQuestions.linkText}
                </a>
              </Card.Text> */}
              <Card.Text>
              Children under the age of 16 can consent to their own treatment if they're believed to have enough intelligence, 
              competence and understanding to fully appreciate what's involved in their treatment. 
              This is known as being Gillick competent.
              </Card.Text>
              </Card.Body>
                    <Demographics
                      loading={loading}
                      gender={filterGender}
                      YearGroup={filterYearGroup}
                      ethnicity={props.ethnicityOptions}
                    />
                    </WizardStep> 
                 <WizardStep >
                    <DuplicateRecord/>
                </WizardStep>
                <WizardStep validationSchema={FormValidator.MenACWYTdIPV}>
                  <MenACWYTdIPVQuestions
                  loading={loading}
                  filterRefusal={filterRefusal}
                  filterRelationships={filterRelationships}
                  vaccineQuestions={props.vaccineQuestions}
                  />
                </WizardStep>
              </Wizard>
              </Col>
              </Row>
            );
    }
}

