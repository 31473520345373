import * as yup from 'yup';

const re = /^(19|20)\d{2}$/;

export default function year() {
  return yup
    .string()
    .matches(re, `Enter a valid 4 digit year`)
    .typeError(`Enter a valid 4 digit year`)
    .label(`Year`)
    .required();
}
