const { hostname, protocol } = window.location;
const host =
  process.env.NODE_ENV === 'production'
    ? `https://api.bchcschoolagevaccs.nhs.uk`
    : `${protocol}//${hostname}:8080`;

function send(path, token, model, method) {
  const url = host + path; // change to api

  const headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const request = {
    credentials: 'omit',
    method: method === 'POST' ? 'POST' : 'GET',
    headers,
    referrer: 'no-referrer',
  };

  if (model) {
    request['body'] = JSON.stringify(model);
  }

  return new Promise((resolve, reject) => {
    fetch(url, request)
      .then((res) => {
        if (res.status === 200) {
          return res.json().then(resolve).catch(reject);
        } else if (res.status === 204) {
          return resolve(null);
        } else {
          return res.json().then(reject).catch(reject);
        }
      })
      .catch(reject);
  });
}

// function addressSend(url, method) {
//   const request = {
//     credentials: 'omit',
//     method: method === 'POST' ? 'POST' : 'GET',

//     referrer: 'no-referrer',
//   };

//   return new Promise((resolve, reject) => {
//     fetch(url, request)
//       .then((res) => {
//         if (res.status === 200) {
//           return res.json().then(resolve).catch(reject);
//         } else if (res.status === 204) {
//           return resolve(null);
//         } else {
//           return res.json().then(reject).catch(reject);
//         }
//       })
//       .catch(reject);
//   });
// }



function pdfSend(path, token, model, method) {
  const url = host + path;

  const headers = {
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const request = {
    credentials: 'omit',
    method: method === 'POST' ? 'POST' : 'GET',
    headers,
    referrer: 'no-referrer',
  };

  if (model) {
    request['body'] = JSON.stringify(model);
  }

  return new Promise((resolve, reject) => {
    fetch(url, request)
      .then((res) => {
        if (res.status === 200) {
          return res.blob().then(resolve).catch(reject);
        } else if (res.status === 204) {
          return resolve(null);
        } else {
          return res.json().then(reject).catch(reject);
        }
      })
      .catch(reject);
  });
}

export function login(consentLetterReferenceCode, accessCode) {
  return send(
    '/login',
    null,
    { consentLetterReferenceCode, accessCode },
    'POST',
  );
}

export function submitHPVConsent(token, values) {
  return send('/consentHPV', token, values, 'POST');
}

export function submitFluConsent(token, values) {
  return send('/consentFlu', token, values, 'POST');
}

export function submitMMRConsent(token, values) {
  return send('/consentMMR', token, values, 'POST');
}

export function submitConsentMenACWYTdIPV(token, values) {
  return send('/consentMenACWYTdIPV', token, values, 'POST');
}

export function getEthnicities(token) {
  return send('/ethnicity', token, null, 'GET');
}

export function getPatientOptions(token) {
  return send('/patientOptions', token, null, 'GET');
}

export function duplicateCheck(value) {
  return send('/duplicateCheck', null, value, 'POST');
}

// export function getAddresses(apiKey, country, postcode) {
//   return addressSend(
//     `https://ws.postcoder.com/pcw/${apiKey}/address/${country}/${encodeURIComponent(
//       postcode,
//     )}?format=json&lines=2&exclude=organisation&addtags=postalcounty&identifier=IMMS`,
//     'GET',
//   );
// }

export function getAddresses_OS(postcode) {
  return send('/getOSAddressResults', null, { postcode }, 'POST');
}

export function generatePDF(values, token, vaccineQuestions) {
  return pdfSend('/pdf-download', token, { values, vaccineQuestions }, 'POST');
}

export function generatePDFMen(values, token, vaccineQuestions) {
  return pdfSend(
    '/pdf-download-men',
    token,
    { values, vaccineQuestions },
    'POST',
  );
}
