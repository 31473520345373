import CustomFieldError from '../../../formFields/customFieldError.js';
import SelectField from '../../../formFields/selectField.js';
import SelectFieldId from '../../../formFields/selectFieldId.js';
import { Form, Row, Col,Card } from 'react-bootstrap';
import RadioField from '../../../formFields/RadioField.js';
import InputField from '../../../formFields/inputField.js';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';

export default function MenACWYTdIPVQuestions(props) {
  const { values, errors, touched, handleChange } = useFormikContext();
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 20); // 20 prevent scrolling issues, not firing on build
  }, []);

  return (
    <>
      <Card.Title className="mt-4">
                      I have understood the information given to me about the
                      diphtheria, tetanus and polio vaccinations (Td/IPV). I
                      confirm I have parental responsibility for this child.
                    </Card.Title>
                    <Form.Group>
                      <Form.Check.Label className="mb-3">
                        Tick one box below (Td/IPV)*
                      </Form.Check.Label>
                      <RadioField
                        disabled={props.loading}
                        name="parentGuardianConsentStatus"
                        value="Given"
                        label="YES I want my child to have the Td/IPV vaccine"
                        type="radio"
                      />
                      <RadioField
                        disabled={props.loading}
                        name="parentGuardianConsentStatus"
                        value="Not Given"
                        label="NO I do not want my child to have the Td/IPV vaccine"
                        type="radio"
                      />
                    </Form.Group>
                    {values.parentGuardianConsentStatus === 'Not Given' && 
                    <Form.Group
                      controlId="parentGuardianConsentReasonForRefusal"
                      className="mb-3 mt-3"
                    >
                      <Form.Label>Reason for refusal (Td/IPV)</Form.Label>
                      <Form.Select
                        disabled={
                          props.loading ||
                          values.parentGuardianConsentStatus === 'Given' ||
                          values.parentGuardianConsentStatus === ''
                        }
                        aria-required={true}
                        isInvalid={
                          touched.parentGuardianConsentReasonForRefusal &&
                          errors.parentGuardianConsentReasonForRefusal
                        }
                        value={values.parentGuardianConsentReasonForRefusal}
                        onChange={handleChange}
                      >
                        {props.filterRefusal.map((e) => (
                          <option
                            key={e.CategoriesID}
                            value={e.CategoriesID}
                            name={'parentGuardianConsentReasonForRefusal'}
                          >
                            {e.CategoryDesc}
                          </option>
                        ))}
                      </Form.Select>
                      {touched.parentGuardianConsentReasonForRefusal &&
                        errors.parentGuardianConsentReasonForRefusal && (
                          <p className="radioError">
                            {errors.parentGuardianConsentReasonForRefusal}
                          </p>
                        )}
                    </Form.Group>}
                    {values.parentGuardianConsentReasonForRefusal === '62' && ( //Had elsewhere
                      <InputField
                        name="parentGuardianConsentReasonForRefusalComments"
                        type="text"
                        label="Please say when and where"
                        placeholder="Please say when and where"
                        disabled={
                          props.loading ||
                          values.parentGuardianConsentStatus === 'Given' ||
                          values.parentGuardianConsentReasonForRefusal !== '62'
                        }
                      />
                    )}
                    {values.parentGuardianConsentStatus === 'Not Given' && 
                    <Form.Group className="mb-3">
                      <Form.Check.Label className="mb-3">
                        Would you like more information? (Td/IPV)*
                      </Form.Check.Label>
                      <RadioField
                        disabled={
                          props.loading ||
                          values.parentGuardianConsentStatus === 'Given' ||
                          values.parentGuardianConsentStatus === ''
                        }
                        name="parentGuardianNeedMoreInfoRequire"
                        value="Yes"
                        label="Yes"
                        type="radio"
                      />
                      <RadioField
                        disabled={
                          props.loading ||
                          values.parentGuardianConsentStatus === 'Given' ||
                          values.parentGuardianConsentStatus === ''
                        }
                        name="parentGuardianNeedMoreInfoRequire"
                        value="No"
                        label="No"
                        type="radio"
                      />
                    </Form.Group>}
                    <Form.Group
                      controlId="parentGuardianConsentRelationshipForChild"
                      className="mb-3"
                    >
                      <Form.Label>Relationship to child (Td/IPV)*</Form.Label>
                      <Form.Select
                        aria-required={true}
                        isInvalid={
                          touched.parentGuardianConsentRelationshipForChild &&
                          errors.parentGuardianConsentRelationshipForChild
                        }
                        value={values.parentGuardianConsentRelationshipForChild}
                        onChange={handleChange}
                        disabled={props.loading}
                      >
                        <option value={''}>Please select</option>
                        {props.filterRelationships.map((e) => (
                          <option
                            key={e.CategoriesID}
                            value={e.CategoriesID}
                            name={'parentGuardianConsentRelationshipForChild'}
                          >
                            {e.CategoryDesc}
                          </option>
                        ))}
                      </Form.Select>
                      {touched.parentGuardianConsentRelationshipForChild &&
                        errors.parentGuardianConsentRelationshipForChild && (
                          <p className="radioError">
                            {errors.parentGuardianConsentRelationshipForChild}
                          </p>
                        )}
                    </Form.Group>
                    {values.parentGuardianConsentRelationshipForChild === '78' && 
                    <InputField
                      name="parentGuardianConsentRelationshipForChildOther"
                      type="text"
                      label="Relationship to child other (Td/IPV)"
                      placeholder="Relationship to child other (Td/IPV)"
                      disabled={
                        props.loading ||
                        values.parentGuardianConsentRelationshipForChild !==
                          '78' //Other
                      }
                    />}
                    <InputField
                      name="parentGuardianConsentSignatureName"
                      type="text"
                      label="Your name (Td/IPV)*"
                      placeholder="Your name (Td/IPV)"
                      disabled={props.loading}
                    />

                    <Form.Group>
                      <Form.Label>
                        Submission Date (Td/IPV)
                      </Form.Label>


                      {/* <div className="mb-2">
                        <Button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => setCurrentDate(setFieldValue, false)}
                        >
                          <span>
                            <img
                              className="image-button"
                              src={CalendarIcon}
                              alt="calendar click for todays date"
                            />
                          </span>
                          Today
                        </Button>
                      </div> */}
                      <Row>
                        <Col xs={12} sm={12} md={4}>
                          <InputField
                            name="parentGuardianConsentSignatureDay"
                            type="text"
                            label="Day"
                            value={moment().date()}
                            placeholder="Td/IPV Day"
                            disabled={true}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={4}>
                          <InputField
                            name="parentGuardianConsentSignatureMonth"
                            type="text"
                            label="Month"
                            value={1 + moment().month()}
                            placeholder="Td/IPV Month"
                            disabled={true}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={4}>
                          <InputField
                            name="parentGuardianConsentSignatureYear"
                            type="text"
                            label="Year"
                            value={moment().year()}
                            placeholder="Td/IPV Year"
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Card.Title className="mb-4 mt-4">
                      I have understood the information given to me about the
                      meningitis vaccine (MenACWY). I confirm I have parental
                      responsibility for this child.
                    </Card.Title>
                    <Form.Group>
                      <Form.Check.Label className="mb-3">
                        Tick one box below (MenACWY)*
                      </Form.Check.Label>
                      <RadioField
                        disabled={props.loading}
                        name="parentGuardianConsentStatusMenACWY"
                        value="Given"
                        label="YES I want my child to have the meningitis vaccine (MenACWY) vaccine"
                        type="radio"
                      />
                      <RadioField
                        disabled={props.loading}
                        name="parentGuardianConsentStatusMenACWY"
                        value="Not Given"
                        label="NO I do not want my child to have meningitis vaccine (MenACWY) vaccine"
                        type="radio"
                      />
                    </Form.Group>
                    {values.parentGuardianConsentStatusMenACWY === 'Not Given' && 
                    <Form.Group
                      controlId="parentGuardianConsentReasonForRefusalMenACWY"
                      className="mb-3 mt-3"
                    >
                      <Form.Label>Reason for refusal (MenACWY)</Form.Label>
                      <Form.Select
                        disabled={
                          props.loading ||
                          values.parentGuardianConsentStatusMenACWY ===
                            'Given' ||
                          values.parentGuardianConsentStatusMenACWY === ''
                        }
                        aria-required={true}
                        isInvalid={
                          touched.parentGuardianConsentReasonForRefusalMenACWY &&
                          errors.parentGuardianConsentReasonForRefusalMenACWY
                        }
                        value={
                          values.parentGuardianConsentReasonForRefusalMenACWY
                        }
                        onChange={handleChange}
                      >
                        {props.filterRefusal.map((e) => (
                          <option
                            key={e.CategoriesID}
                            value={e.CategoriesID}
                            name={
                              'parentGuardianConsentReasonForRefusalMenACWY'
                            }
                          >
                            {e.CategoryDesc}
                          </option>
                        ))}
                      </Form.Select>
                      {touched.parentGuardianConsentReasonForRefusalMenACWY &&
                        errors.parentGuardianConsentReasonForRefusalMenACWY && (
                          <p className="radioError">
                            {
                              errors.parentGuardianConsentReasonForRefusalMenACWY
                            }
                          </p>
                        )}
                    </Form.Group>}
                    {values.parentGuardianConsentReasonForRefusalMenACWY ===
                      '62' && ( //Had elsewhere
                      <InputField
                        name="parentGuardianConsentReasonForRefusalCommentsMenACWY"
                        type="text"
                        label="Please say when and where"
                        placeholder="Please say when and where"
                        disabled={
                          props.loading ||
                          values.parentGuardianConsentStatusMenACWY ===
                            'Given' ||
                          values.parentGuardianConsentReasonForRefusalMenACWY !==
                            '62'
                        }
                      />
                    )}
                    {values.parentGuardianConsentStatusMenACWY === 'Not Given' && 
                    <Form.Group className="mb-3">
                      <Form.Check.Label className="mb-3">
                        Would you like more information? (MenACWY)*
                      </Form.Check.Label>
                      <RadioField
                        disabled={
                          props.loading ||
                          values.parentGuardianConsentStatusMenACWY ===
                            'Given' ||
                          values.parentGuardianConsentStatusMenACWY === ''
                        }
                        name="parentGuardianNeedMoreInfoRequireMenACWY"
                        value="Yes"
                        label="Yes"
                        type="radio"
                      />
                      <RadioField
                        disabled={
                          props.loading ||
                          values.parentGuardianConsentStatusMenACWY ===
                            'Given' ||
                          values.parentGuardianConsentStatusMenACWY === ''
                        }
                        name="parentGuardianNeedMoreInfoRequireMenACWY"
                        value="No"
                        label="No"
                        type="radio"
                      />
                    </Form.Group>}

                    <Form.Group
                      controlId="parentGuardianConsentRelationshipForChildMenACWY"
                      className="mb-2"
                    >
                      <Form.Label>Relationship to child (MenACWY)*</Form.Label>
                      <Form.Select
                        aria-required={true}
                        isInvalid={
                          touched.parentGuardianConsentRelationshipForChildMenACWY &&
                          errors.parentGuardianConsentRelationshipForChildMenACWY
                        }
                        value={
                          values.parentGuardianConsentRelationshipForChildMenACWY
                        }
                        onChange={handleChange}
                        disabled={props.loading}
                      >
                        <option value={''}>Please select</option>
                        {props.filterRelationships.map((e) => (
                          <option
                            key={e.CategoriesID}
                            value={e.CategoriesID}
                            name={
                              'parentGuardianConsentRelationshipForChildMenACWY'
                            }
                          >
                            {e.CategoryDesc}
                          </option>
                        ))}
                      </Form.Select>
                      {touched.parentGuardianConsentRelationshipForChildMenACWY &&
                        errors.parentGuardianConsentRelationshipForChildMenACWY && (
                          <p className="radioError">
                            {
                              errors.parentGuardianConsentRelationshipForChildMenACWY
                            }
                          </p>
                        )}
                    </Form.Group>
                    {values.parentGuardianConsentRelationshipForChildMenACWY ==='78' &&
                    <InputField
                      name="parentGuardianConsentRelationshipForChildMenACWYOther"
                      type="text"
                      label="Relationship to child other (MenACWY)"
                      placeholder="Relationship to child other (MenACWY)"
                      disabled={
                        props.loading ||
                        values.parentGuardianConsentRelationshipForChildMenACWY !==
                          '78' //Other
                      }
                    />}
                    <InputField
                      name="parentGuardianConsentSignatureNameMenACWY"
                      type="text"
                      label="Your signature (MenACWY)*"
                      placeholder="Your signature (MenACWY)*"
                      disabled={props.loading}
                    />
                    <Form.Group>
                      <Form.Label>
                       Submission Date (MenACWY)
                      </Form.Label>
                      {/* <div className="mb-2">
                        <Button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => setCurrentDate(setFieldValue, true)}
                        >
                          <span>
                            <img
                              className="image-button"
                              src={CalendarIcon}
                              alt="calendar click for todays date"
                            />
                          </span>
                          Today
                        </Button>
                      </div> */}
                      <Row>
                        <Col xs={12} sm={12} md={4}>
                          <InputField
                            name="parentGuardianConsentSignatureDateMenACWYDay"
                            type="text"
                            label="Day*"
                            placeholder="MenACWY Day"
                            value={moment().date()}
                            disabled={true}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={4}>
                          <InputField
                            name="parentGuardianConsentSignatureDateMenACWYMonth"
                            type="text"
                            label="Month*"
                            value={1 + moment().month()}
                            placeholder="MenACWY Month"
                            disabled={true}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={4}>
                          <InputField
                            name="parentGuardianConsentSignatureDateMenACWYYear"
                            type="text"
                            label="Year*"
                            placeholder="MenACWY Year"
                            value={moment().year()}
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <div style={(values.parentGuardianConsentStatus==='Given' ||
                            values.parentGuardianConsentStatusMenACWY ==='Given') ? {} : { display: 'none' }} >
                    <hr className="form-divider" />
                    <Card.Title className="mb-4 mt-4">
                      Health Questionnaire
                    </Card.Title>
                    <Form.Group className="form-input-spacing">
                      <Form.Check.Label className="mb-3">
                        {props.vaccineQuestions.q1}
                      </Form.Check.Label>
                      <Form.Check
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given')
                        }
                        label="Yes"
                        type="radio"
                        name="q1"
                        value={'Yes'}
                        checked={values.q1 === 'Yes'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q1 && errors.q1}
                      ></Form.Check>
                      <Form.Check
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given')
                        }
                        type="radio"
                        label="No"
                        name="q1"
                        value={'No'}
                        checked={values.q1 === 'No'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q1 && errors.q1}
                      ></Form.Check>
                      {/* <Form.Check
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given')
                        }
                        type="radio"
                        label="Don't know"
                        name="q1"
                        value={'DontKnow'}
                        checked={values.q1 === 'DontKnow'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q1 && errors.q1}
                      ></Form.Check> */}
                      {touched.q1 && errors.q1 && (
                        <p className="radioError">{errors.q1}</p>
                      )}
                    </Form.Group>
                    {values.q1 === 'Yes' && 
                    <Form.Group
                      controlId="q1Comment"
                      className="form-input-spacing"
                    >
                      <Form.Label>
                        {props.vaccineQuestions.q1Comment}
                      </Form.Label>
                      <Form.Control
                        placeholder={props.vaccineQuestions.q1Comment}
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given') ||
                          values.q1 === 'No' ||
                          values.q1 === ''
                        }
                        type="text"
                        name="q1Comment"
                        value={values.q1Comment}
                        onChange={handleChange}
                        isInvalid={touched.q1Comment && errors.q1Comment}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.q1Comment}
                      </Form.Control.Feedback>
                    </Form.Group>}
                    <Form.Group className="form-input-spacing">
                      <Form.Check.Label className="mb-3">
                        {props.vaccineQuestions.q2}
                      </Form.Check.Label>
                      <Form.Check
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given')
                        }
                        label="Yes"
                        type="radio"
                        name="q2"
                        value={'Yes'}
                        checked={values.q2 === 'Yes'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q2 && errors.q2}
                      ></Form.Check>
                      <Form.Check
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given')
                        }
                        type="radio"
                        label="No"
                        name="q2"
                        value={'No'}
                        checked={values.q2 === 'No'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q2 && errors.q2}
                      ></Form.Check>
                      {touched.q2 && errors.q2 && (
                        <p className="radioError">{errors.q2}</p>
                      )}
                    </Form.Group>
                    {values.q2 === 'Yes' && 
                    <Form.Group
                      controlId="q2Comment"
                      className="form-input-spacing"
                    >
                      <Form.Label>
                        {props.vaccineQuestions.q2Comment}
                      </Form.Label>
                      <Form.Control
                        placeholder={props.vaccineQuestions.q2Comment}
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given') ||
                          values.q2 === 'No' ||
                          values.q2 === ''
                        }
                        type="text"
                        name="q2Comment"
                        value={values.q2Comment}
                        onChange={handleChange}
                        isInvalid={touched.q2Comment && errors.q2Comment}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.q2Comment}
                      </Form.Control.Feedback>
                    </Form.Group>}
                    <Form.Group className="form-input-spacing">
                      <Form.Check.Label className="mb-3">
                        {props.vaccineQuestions.q3}
                      </Form.Check.Label>
                      <Form.Check
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given')
                        }
                        label="Yes"
                        type="radio"
                        name="q3"
                        value={'Yes'}
                        checked={values.q3 === 'Yes'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q3 && errors.q3}
                      ></Form.Check>
                      <Form.Check
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given')
                        }
                        type="radio"
                        label="No"
                        name="q3"
                        value={'No'}
                        checked={values.q3 === 'No'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q3 && errors.q3}
                      ></Form.Check>
                      {touched.q3 && errors.q3 && (
                        <p className="radioError">{errors.q3}</p>
                      )}
                    </Form.Group>
                    {values.q3 === 'Yes' && 
                    <Form.Group
                      controlId="q3Comment"
                      className="form-input-spacing"
                    >
                      <Form.Label>If yes, please give details</Form.Label>
                      <Form.Control
                        placeholder="If yes, please give details"
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given') ||
                          values.q3 === 'No' ||
                          values.q3 === ''
                        }
                        type="text"
                        name="q3Comment"
                        value={values.q3Comment}
                        onChange={handleChange}
                        isInvalid={touched.q3Comment && errors.q3Comment}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.q3Comment}
                      </Form.Control.Feedback>
                    </Form.Group>}
                    <Form.Group className="form-input-spacing">
                      <Form.Check.Label className="mb-3">
                        {props.vaccineQuestions.q4}
                      </Form.Check.Label>
                      <Form.Check
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given')
                        }
                        label="Yes"
                        type="radio"
                        name="q4"
                        value={'Yes'}
                        checked={values.q4 === 'Yes'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q4 && errors.q4}
                      ></Form.Check>
                      <Form.Check
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given')
                        }
                        type="radio"
                        label="No"
                        name="q4"
                        value={'No'}
                        checked={values.q4 === 'No'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q4 && errors.q4}
                      ></Form.Check>
                      {touched.q4 && errors.q4 && (
                        <p className="radioError">{errors.q4}</p>
                      )}
                    </Form.Group>
                    {values.q4 === 'Yes' && 
                    <Form.Group
                      controlId="q4Comment"
                      className="form-input-spacing"
                    >
                      <Form.Label>If yes, please give details</Form.Label>
                      <Form.Control
                        placeholder="If yes, please give details"
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given') ||
                          values.q4 === 'No' ||
                          values.q4 === ''
                        }
                        type="text"
                        name="q4Comment"
                        value={values.q4Comment}
                        onChange={handleChange}
                        isInvalid={touched.q4Comment && errors.q4Comment}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.q4Comment}
                      </Form.Control.Feedback>
                    </Form.Group>}
                    <Form.Group className="form-input-spacing">
                      <Form.Check.Label className="mb-3">
                        {props.vaccineQuestions.q5}
                      </Form.Check.Label>
                      <Form.Check
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given')
                        }
                        label="Yes"
                        type="radio"
                        name="q5"
                        value={'Yes'}
                        checked={values.q5 === 'Yes'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q5 && errors.q5}
                      ></Form.Check>
                      <Form.Check
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given')
                        }
                        type="radio"
                        label="No"
                        name="q5"
                        value={'No'}
                        checked={values.q5 === 'No'}
                        onChange={handleChange}
                        isInvalid={touched.q5 && errors.q5}
                      ></Form.Check>
                      {touched.q5 && errors.q5 && (
                        <p className="radioError">{errors.q5}</p>
                      )}
                    </Form.Group>
                    {values.q5 === 'Yes' && 
                    <Form.Group
                      controlId="q5Comment"
                      className="form-input-spacing"
                    >
                      <Form.Label>If yes, please give details</Form.Label>
                      <Form.Control
                        placeholder="If yes, please give details"
                        disabled={
                          props.loading ||
                          (values.parentGuardianConsentStatus === 'Not Given' &&
                            values.parentGuardianConsentStatusMenACWY ===
                              'Not Given') ||
                          values.q5 === 'No' ||
                          values.q5 === ''
                        }
                        type="text"
                        name="q5Comment"
                        value={values.q5Comment}
                        onChange={handleChange}
                        isInvalid={touched.q5Comment && errors.q5Comment}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.q5Comment}
                      </Form.Control.Feedback>
                    </Form.Group>}
                    {props.vaccineQuestions.q6 && (
                      <>
                        <Form.Group className="form-input-spacing">
                          <Form.Check.Label className="mb-3">
                            {props.vaccineQuestions.q6}
                          </Form.Check.Label>
                          <Form.Check
                            disabled={
                              props.loading ||
                              (values.parentGuardianConsentStatus ===
                                'Not Given' &&
                                values.parentGuardianConsentStatusMenACWY ===
                                  'Not Given')
                            }
                            label="Yes"
                            type="radio"
                            name="q6"
                            value={'Yes'}
                            checked={values.q6 === 'Yes'}
                            onChange={handleChange}
                            aria-required={true}
                            isInvalid={touched.q6 && errors.q6}
                          ></Form.Check>
                          <Form.Check
                            disabled={
                              props.loading ||
                              (values.parentGuardianConsentStatus ===
                                'Not Given' &&
                                values.parentGuardianConsentStatusMenACWY ===
                                  'Not Given')
                            }
                            type="radio"
                            label="No"
                            name="q6"
                            value={'No'}
                            checked={values.q6 === 'No'}
                            onChange={handleChange}
                            aria-required={true}
                            isInvalid={touched.q6 && errors.q6}
                          ></Form.Check>
                          {touched.q6 && errors.q6 && (
                            <p className="radioError">{errors.q6}</p>
                          )}
                        </Form.Group>
                        {values.q6 === 'Yes' && 
                        <Form.Group
                          controlId="q6Comment"
                          className="form-input-spacing"
                        >
                          <Form.Label>If yes, please give details</Form.Label>
                          <Form.Control
                            placeholder="If yes, please give details"
                            disabled={
                              props.loading ||
                              (values.parentGuardianConsentStatus ===
                                'Not Given' &&
                                values.parentGuardianConsentStatusMenACWY ===
                                  'Not Given') ||
                              values.q6 === 'No' ||
                              values.q6 === ''
                            }
                            type="text"
                            name="q6Comment"
                            value={values.q6Comment}
                            onChange={handleChange}
                            isInvalid={touched.q6Comment && errors.q6Comment}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.q6Comment}
                          </Form.Control.Feedback>
                        </Form.Group>}
                      </>
                    )}

                    <Form.Group
                      controlId="parentGuardianFurtherInformation"
                      className="form-input-spacing"
                    >
                      <Form.Label>
                        Please add any futher information you feel would be
                        useful
                      </Form.Label>
                      <Form.Control
                        placeholder="Please add any futher information"
                        disabled={props.loading}
                        type="text"
                        name="parentGuardianFurtherInformation"
                        value={values.parentGuardianFurtherInformation}
                        onChange={handleChange}
                        isInvalid={
                          touched.parentGuardianFurtherInformation &&
                          errors.parentGuardianFurtherInformation
                        }
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.parentGuardianFurtherInformation}
                      </Form.Control.Feedback>
                    </Form.Group>
                    </div>
                    {/* Checks if there are errors and fields have been touched */}
                    {Object.keys(touched).length > 0 &&
                      Object.keys(errors).length > 0 && (
                        <p className="radioError mt-5">
                          Please complete all required fields (*)
                        </p>
                      )}

    </>
  );
}
