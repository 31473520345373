import * as yup from 'yup';

export default function nullableEmail(label) {
  return yup
    .string()
    .max(75)
    .email('Please enter a valid email address')
    .label(label)
    .nullable();
}
