import * as yup from 'yup';
import moment from 'moment';

import {
  stringRequired,
  stringMinMaxRequired,
  postCode,
  nullableString,
  detailsString,
  nullableNhsNo,
  nullableTelephone,
  day,
  month,
  year,
  nullableEmail,
  nullableStringMax,
} from '../fields/index.js';

export default yup.object().shape({
  // patientForename: stringMinMaxRequired('Child forename', 1, 255),
  // patientSurname: stringMinMaxRequired('Child surname', 1, 255),
  // patientAddressLine1: stringMinMaxRequired('Address Line 1', 1, 500),
  // patientAddressLine2: stringMinMaxRequired('Address Line 2', 1, 500),
  // patientAddressLine3: nullableStringMax('Incorrect format', 500),
  // patientAddressLine4: stringMinMaxRequired('Address Line 4', 1, 500),
  // patientPostCode: postCode('Postcode'),
  // dobDay: day(),
  // dobMonth: month(),
  // dobYear: year(),
  // patientDOB: yup.string().test({
  //   test: function () {
  //     const now = moment();
  //     if (this.parent.dobYear && this.parent.dobMonth && this.parent.dobDay) {
  //       const dob = moment(
  //         new Date(
  //           `${this.parent.dobYear}/${this.parent.dobMonth}/${this.parent.dobDay}`,
  //         ),
  //       );
  //       if (moment().diff(moment(dob), 'years') > 18) {
  //         return this.createError({
  //           path: 'patientDOB',
  //           message: 'Child age must be less than 18 years',
  //         });
  //       } else if (moment(dob).isAfter(now)) {
  //         return this.createError({
  //           path: 'patientDOB',
  //           message: 'Child date of birth must be in the past',
  //         });
  //       } else {
  //         return true;
  //       }
  //     } else {
  //       return true;
  //     }
  //   },
  // }),

  // patientGender: stringRequired('Gender'),
  // patientGenderOther: detailsString(
  //   'patientGender',
  //   stringMinMaxRequired('Patient Gender Other', 1, 50),
  //   'O',
  // ),
  // ethnicity: stringRequired('Ethnicity'),
  // parentGuardianConsentPhoneCarer: nullableTelephone(
  //   'Telephone number - incorrect format',
  // ),
  // parentGuardianConsentEmailAddress: nullableEmail('Email'),
  // parentGuardianConsentConfirmEmailAddress: yup.string()
  //     .oneOf([yup.ref('parentGuardianConsentEmailAddress')], 'Email and confirm email does not match'),
  // nHSNumber: nullableNhsNo('NHS number'),
  // yearGroup: stringMinMaxRequired('Year group', 1, 25),
  // yearClass: nullableStringMax('Year class', 25),
  parentGuardianConsentStatus: stringRequired('Please confirm vaccine'),
  parentGuardianConsentReasonForRefusal: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Selected NO. Please select reason for refusal'),
    'Not Given',
  ),
  parentGuardianConsentReasonForRefusalComments: yup.string().test({
    message: 'Selected already had elsewhere. Please say when and where',
    test: function (value) {
      if (
        !value &&
        this.parent.parentGuardianConsentReasonForRefusal === '62' &&
        this.parent.parentGuardianConsentStatus === 'Not Given'
      ) {
        return false;
      } else {
        return true; //pass through
      }
    },
  }),
  parentGuardianNeedMoreInfoRequire: yup.string().test({
    message: 'Please indicate if you would you like more information',
    test: function (value) {
      if (!value && this.parent.parentGuardianConsentStatus === 'Not Given') {
        return false;
      } else {
        return true; //pass through
      }
    },
  }),

  parentGuardianConsentRelationshipForChild: stringRequired('Relationship'),
  parentGuardianConsentRelationshipForChildOther: detailsString(
    'parentGuardianConsentRelationshipForChild',
    stringMinMaxRequired('Relationship to child other', 1, 100),
    '78', //Other
  ),

  parentGuardianConsentSignatureName: stringMinMaxRequired('Signature', 1, 100),
  parentGuardianConsentSignatureDay: day(),
  parentGuardianConsentSignatureMonth: month(),
  parentGuardianConsentSignatureYear: year(),

  //Health questionnaire

  //Flu - Has your child had a flu vaccination since September this year?*

  q1: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Q1'),
    'Given',
  ),
  q1Comment: detailsString(
    'q1',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),
  //Flu - Does your child have any allergies?*

  q2: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Q2'),
    'Given',
  ),
  q2Comment: detailsString(
    'q2',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),
  //Flu - Does your child have a severe egg allergy (requiring intensive care admission)*

  q3: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Q3'),
    'Given',
  ),
  q3Comment: detailsString(
    'q3',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),
  //Flu - Does your child have asthma?*

  q4: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Q4'),
    'Given',
  ),
  q4Comment: detailsString(
    'q4',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),
  //Flu - Has your child ever been admitted to intensive care because of their asthma?

  q5: yup.string().test({
    message: 'Q5 is a required field',
    test: function (value) {
      if (this.parent.q4 === 'Yes' && !value) {
        return false;
      } else {
        return true;
      }
    },
  }),

  q5Comment: yup.string().test({
    message: 'Q5 is a required field',
    test: function (value) {
      if (this.parent.q4 === 'Yes' && this.parent.q5 === 'Yes' && !value) {
        return false;
      } else {
        return true;
      }
    },
  }),
  //Flu - Does your child have any serious health conditions or are they receiving treatment that severely weakens their immune system (e.g. treatment for leukaemia?

  q6: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Q6'),
    'Given',
  ),

  q6Comment: detailsString(
    'q6',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),
  //Flu - Is anyone in your family currently receiving treatment that severly affects their immune system (e.g. they need to be kept in isolation)?

  q7: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Q7'),
    'Given',
  ),
  q7Comment: detailsString(
    'q7',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),
  //Flu - If yes to the above questions can your child avoid close contact with them for two weeks after receiving the vaccination

  q8: yup.string().test({
    message: 'Q8 is a required field',
    test: function (value) {
      if (
        // If all values are not equal to yes - pass through
        this.parent.q1 !== 'Yes' &&
        this.parent.q2 !== 'Yes' &&
        this.parent.q3 !== 'Yes' &&
        this.parent.q4 !== 'Yes' &&
        this.parent.q5 !== 'Yes' &&
        this.parent.q6 !== 'Yes' &&
        this.parent.q7 !== 'Yes'
      ) {
        return true;
      } else {
        //check value if nothing then validate
        if (value === 'Yes' || value === 'No') {
          return true;
        } else {
          return false;
        }
      }
    },
  }),

  q8Comment: yup.string().test({
    message: 'Q8 comment is a required field',
    test: function (value) {
      if (
        // If all values are not equal to yes - pass through
        this.parent.q1 !== 'Yes' &&
        this.parent.q2 !== 'Yes' &&
        this.parent.q3 !== 'Yes' &&
        this.parent.q4 !== 'Yes' &&
        this.parent.q5 !== 'Yes' &&
        this.parent.q6 !== 'Yes' &&
        this.parent.q7 !== 'Yes'
      ) {
        return true;
      } else {
        //check value if nothing then validate
        if (this.parent.q8 === 'No' && !value) {
          return false;
        } else {
          return true;
        }
      }
    },
  }),

  //Flu - Does your child take any medications (including salisylate therapy e.g. aspirin?)*

  q9: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Q9'),
    'Given',
  ),
  q9Comment: detailsString(
    'q9',
    stringRequired('Selected Yes. Question details'),
    'Yes',
  ),
  parentGuardianFurtherInformation: nullableString('Incorrect format'),
});
