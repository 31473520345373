export function pdfReferenceValues(
  pdfFormValues,
  ethnicity,
  gender,
  yearGroup,
  refusal,
  relationships,
) {
  let pdf = pdfFormValues;

  //Filter selects for pdf descriptions
  pdf['ethnicityDesc'] = ethnicity.find(
    (x) => x.EthnicityCode === pdfFormValues.ethnicity,
  ).EthnicityDesc;

  pdf['yearGroup'] = yearGroup.find(
    (x) => x.CategoryValueCode === pdfFormValues.yearGroup,
  ).CategoryDesc;

  pdf['genderDesc'] = gender.find(
    (x) => x.CategoryValueCode === pdfFormValues.patientGender,
  ).CategoryDesc;

  pdf['relationshipDesc'] = relationships.find(
    (x) =>
      x.CategoriesID ===
      parseInt(pdfFormValues.parentGuardianConsentRelationshipForChild, 10),
  ).CategoryDesc;

  if (pdfFormValues.parentGuardianConsentReasonForRefusal) {
    pdf['refusalReasonDesc'] = refusal.find(
      (x) =>
        x.CategoriesID ===
        parseInt(pdfFormValues.parentGuardianConsentReasonForRefusal, 10),
    ).CategoryDesc;
  }

  return pdf;
}

export function pdfReferenceValuesMenACWYTdIPV(
  pdfFormValues,
  ethnicity,
  gender,
  yearGroup,
  refusal,
  relationships,
) {
  let pdf = pdfFormValues;

  //Filter selects for pdf descriptions
  pdf['ethnicityDesc'] = ethnicity.find(
    (x) => x.EthnicityCode === pdfFormValues.ethnicity,
  ).EthnicityDesc;

  pdf['yearGroup'] = yearGroup.find(
    (x) => x.CategoryValueCode === pdfFormValues.yearGroup,
  ).CategoryDesc;

  pdf['genderDesc'] = gender.find(
    (x) => x.CategoryValueCode === pdfFormValues.patientGender,
  ).CategoryDesc;

  pdf['relationshipDescMen'] = relationships.find(
    (x) =>
      x.CategoriesID ===
      parseInt(
        pdfFormValues.parentGuardianConsentRelationshipForChildMenACWY,
        10,
      ),
  ).CategoryDesc;

  pdf['relationshipDescTdIPV'] = relationships.find(
    (x) =>
      x.CategoriesID ===
      parseInt(pdfFormValues.parentGuardianConsentRelationshipForChild, 10),
  ).CategoryDesc;

  if (pdfFormValues.parentGuardianConsentReasonForRefusalMenACWY) {
    pdf['refusalReasonDescMen'] = refusal.find(
      (x) =>
        x.CategoriesID ===
        parseInt(
          pdfFormValues.parentGuardianConsentReasonForRefusalMenACWY,
          10,
        ),
    ).CategoryDesc;
  }

  if (pdfFormValues.parentGuardianConsentReasonForRefusal) {
    pdf['refusalReasonDescTdIPV'] = refusal.find(
      (x) =>
        x.CategoriesID ===
        parseInt(pdfFormValues.parentGuardianConsentReasonForRefusal, 10),
    ).CategoryDesc;
  }

  return pdf;
}
