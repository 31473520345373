import * as yup from 'yup';

import {
  stringRequired,
  stringMinMaxRequired,
  nullableString,
  detailsString,
  day,
  month,
  year,
  nullableDay,
  nullableMonth,
  nullableYear,
} from '../fields/index.js';

export default yup.object().shape({
  
  parentGuardianConsentStatus: stringRequired('Please confirm vaccine'),
  parentGuardianConsentReasonForRefusal: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Selected NO. Please select reason for refusal'),
    'Not Given',
  ),
  parentGuardianConsentReasonForRefusalComments: yup.string().test({
    message: 'Selected already had elsewhere. Please say when and where',
    test: function (value) {
      if (
        !value &&
        this.parent.parentGuardianConsentReasonForRefusal === '62' &&
        this.parent.parentGuardianConsentStatus === 'Not Given'
      ) {
        return false;
      } else {
        return true; //pass through
      }
    },
  }),
  parentGuardianNeedMoreInfoRequire: yup.string().test({
    message: 'Please indicate if you would you like more information',
    test: function (value) {
      if (!value && this.parent.parentGuardianConsentStatus === 'Not Given') {
        return false;
      } else {
        return true; //pass through
      }
    },
  }),

  parentGuardianConsentRelationshipForChild: stringRequired('Relationship'),
  parentGuardianConsentRelationshipForChildOther: detailsString(
    'parentGuardianConsentRelationshipForChild',
    stringMinMaxRequired('Relationship to child other', 1, 100),
    '78', //Other
  ),

  parentGuardianConsentSignatureName: stringMinMaxRequired('Signature', 1, 100),
  parentGuardianConsentSignatureDay: day(),
  parentGuardianConsentSignatureMonth: month(),
  parentGuardianConsentSignatureYear: year(),

  //Health questionnaire

  //MMR -  Has your child had an MMR vaccine before?

  q1: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Q1'),
    'Given',
  ),

  q1VaccineWhere: detailsString(
    'q1',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),

  q1VaccineWhenDay: detailsString('q1', nullableDay(), 'Yes'),
  q1VaccineWhenMonth: detailsString('q1', nullableMonth(), 'Yes'),
  q1VaccineWhenYear: detailsString('q1', nullableYear(), 'Yes'),

  //MMR - 2. Has your child ever had a severe allergic reaction or any other problems after other injections/vaccines?*

  q2: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Q2'),
    'Given',
  ),
  q2Comment: detailsString(
    'q2',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),
  //MMR - 3. Does your child have any allergies?*

  q3: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Q3'),
    'Given',
  ),
  q3Comment: detailsString(
    'q3',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),
  //MMR - 4. Does your child have any serious health problems including prolonged bleeding?**

  q4: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Q4'),
    'Given',
  ),
  q4Comment: detailsString(
    'q4',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),
  //mmr - 5. Does your child take any medications?*

  q5: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Q5'),
    'Given',
  ),

  q5Comment: detailsString(
    'q5',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),
  //MMR -6 Has your child had any injections (vaccines) in the last 4 weeks*

  q6: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Q6'),
    'Given',
  ),

  q6Comment: detailsString(
    'q6',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),

  parentGuardianFurtherInformation: nullableString('Incorrect format'),
});
