import { useState } from 'react';
import { Button, Form, Row, Col, Card } from 'react-bootstrap';
import { login } from '../../utils/sendRequest.js';
import * as FormValidator from '../../validation/index.js';
import { Formik } from 'formik';
import InputField from '../formFields/inputField.js';

export default function Login({ loginProcess }) {
  const [authenticating, setAuthenticating] = useState(false);
  const [failed, setFailed] = useState(false);
  const [serverError, setServerError] = useState();

  return (
    <Row>
      {/* <Card bg="warning" className="mb-3 mt-3">
              <Card.Header>Scheduled Maintenance</Card.Header>
              <Card.Body>
                Due to scheduled maintenance activity, consent form will not be available on 26th February 2024 from 6:00 PM to 10:00 PM. Regret the inconvenience caused.
              </Card.Body>
            </Card> */}
      <Col xs={12} sm={12} md={8}>
        <Card>
          <Card.Header className="bg-light h4">Immunisation Login</Card.Header>
          {failed && (
            <Card bg="danger" text="light" className="mb-3 mt-3">
              <Card.Header>Error</Card.Header>
              <Card.Body>
                {serverError ? serverError.message : 'Error logging in'}
              </Card.Body>
            </Card>
          )}
          <Card.Body>
            <Formik
              initialValues={{
                consentLetterReferenceCode: '',
                accessCode: '',
              }}
              validationSchema={FormValidator.login}
              onSubmit={(values) => {
                setAuthenticating(true);
                login(values.consentLetterReferenceCode, values.accessCode)
                  .then((res) => {
                    setAuthenticating(false);
                    loginProcess(res, values.consentLetterReferenceCode);
                  })
                  .catch((error) => {
                    setAuthenticating(false);
                    setFailed(true);
                    setServerError(error);
                  });
              }}
            >
              {({ handleSubmit }) => (
                <Form noValidate onSubmit={handleSubmit} autoComplete="off">
                  <InputField
                    name="consentLetterReferenceCode"
                    type="text"
                    label="Consent Letter Reference Code*"
                    placeholder="Please enter your consent letter reference code"
                    disabled={authenticating}
                  />
                  <InputField
                    name="accessCode"
                    type="text"
                    label="Access Code*"
                    placeholder="Please enter your access code"
                    disabled={authenticating}
                  />
                  <Button
                    type="submit"
                    variant="success"
                    disabled={authenticating}
                  >
                    {authenticating ? (
                      <span>Logging in...</span>
                    ) : (
                      <span>Login</span>
                    )}
                  </Button>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
