import React, { useState, useContext } from 'react';
import { Form, Formik } from 'formik';
import { Button, Row, Col,Card, ProgressBar } from 'react-bootstrap';
import { duplicateCheck } from '../../../utils/sendRequest.js';
import ErrorNotification from '../../base/ErrorNotification.js';
import { mapQuestions } from '../../../utils/mapQuestions.js';
import LoadingSpinner from '../../base/loadingSpinner.js';
import DuplicateRecord from './subForms/duplicateRecord.js';
import UserContext from '../../../context/userContext.js';


export default function Wizard({ children, initialValues, onSubmit }) {
  const [stepNumber, setStepNumber] = useState(0);
  const [stepDescription, setStepDescription] = useState('Demographics');
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);
  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;
  const [progress, setProgress] = useState(0);
  const userDetail = useContext(UserContext);

  const wizardSteps = [
    { id: 0, description: '1. Demographics' },
    { id: 1, description: '2. Duplicate'},
    { id: 2, description: '3. Consent'}
  ];
  const next = (values) => {
    
    if(stepNumber === 0)
    {
      values.vaccineDeliveredCode = userDetail.vaccineCode;
      values.consentLetterReferenceCode =userDetail.consentLetterReferenceCode;
      values.schoolCodeURN = userDetail.schoolCode;
      if(values)
      {
        duplicateCheck(values)
      .then((res) => {
        if(res.code === 100)
        {
          setStepNumber(1);
          values.duplicateReason = res.message;
         setStepDescription('Duplicate');
          console.log("error");
        }
        else if(res.code === 600)
        {
          setSnapshot(values);
          setStepNumber(2);
          setStepDescription('Consent');
          //setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));

        }
        else
        {
          setSnapshot(values);
          setStepNumber(2);
          setStepDescription('Consent');
        }
      })
      .catch((e) => {
        console.log(e);
      });
      }
    }

  };
  const previous = (values, touched) => {
    //pass in touched from next button, to allow reset of touched values and remove error messages
    touched({});
    setSnapshot(values);
    if (stepNumber === 2) {
      setStepNumber(0);
      setStepDescription('Demographics');
      //setProgress(0);
    }
  };
  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag);
    }
    if (isLastStep) {
      setProgress(progress + 25);
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      next(values);
    }
  };

  return (
    <Row>
        <Formik
          initialValues={snapshot}
          onSubmit={handleSubmit}
          validationSchema={step.props.validationSchema}
        >
          {(formik) => (
            <Form noValidate>
              {step}
              <div className="pagination-wizard">
                <div>
                {stepNumber > 0 && stepNumber !== 1 && (
                  <Button
                    onClick={() => previous(formik.values, formik.setTouched)}
                    variant="light"
                    type="button"
                  >
                    Back
                  </Button>
                )}
                </div>
                <div>
                  {stepNumber !== 1 &&
                  <Button
                    disabled={formik.isSubmitting}
                    variant="success"
                    type="submit"
                  >
                    {isLastStep ? 'Submit' : 'Next'}
                  </Button>
                  }
                </div>
              </div>
            </Form>
          )}
        </Formik>
      {/* </Col> */}
    </Row>
  );
}
