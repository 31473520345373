import { useState } from 'react';
import { Button, Form, Row, Col, Card, Alert, ListGroup, ListGroupItem } from 'react-bootstrap';
import { useContext } from 'react';
import UserContext from '../../../context/userContext.js';
import { submitHPVConsent, generatePDF } from '../../../utils/sendRequest.js';
import { pdfReferenceValues } from '../../../utils/pdfReferenceValues.js';
import * as FormValidator from '../../../validation/index.js';
import { dateConversion } from '../../../utils/dateConversion.js';
import Demographics from './subForms/demographics.js';
import HpvQuestions from './subForms/hpvQuestions.js';
import moment from 'moment';
import Wizard from './wizardForm.js';
import DuplicateRecord from './subForms/duplicateRecord.js';
import ParentalResponsibility from './subForms/parentalResponsibility.js';

export default function HPV(props) {
  const userDetail = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const WizardStep = ({ children }) => children;
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [serverError, setServerError] = useState();
  const [pdfFormValues, setPdfFormValues] = useState();

  const filterRelationships = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'PARELAT',
  );

  const filterYearGroup = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'SCHYRSGP',
  );

  const filterGender = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'GENDER',
  );

  const filterRefusal = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'PAREFUSAL' && x.CarePlusCoding !== 'VC',
  );

  const pdfDownload = async () => {
    const pdf = pdfReferenceValues(
      pdfFormValues,
      props.ethnicityOptions,
      filterGender,
      filterYearGroup,
      filterRefusal,
      filterRelationships,
    );

    setProcessing(true);

    try {
      const resPdf = await generatePDF(
        pdf,
        userDetail.token,
        props.vaccineQuestions,
      );

      if (!resPdf) {
        setFailed();
      }

      const file = new Blob([resPdf], { type: 'application/pdf' });
      //IE fix
      // window navigator - detect browser
      //msSaveOrOpenBlob ie method to save a file in a browser
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
        return;
      }

      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `Imm Consent Form ${pdfFormValues.inputDate}.pdf`;
      link.click();
      setTimeout(() => {
        URL.revokeObjectURL(link);
        setProcessing(false);
        setFailed(null);
      }, 100);
    } catch (error) {
      setProcessing(false);
      setFailed(error);
    }
  };

  if (success) {
    return (
      <Row>
        <Col xs={12} sm={12} md={8}>
          {failed && (
            <Alert variant="danger">Error downloading Consent Form PDF</Alert>
          )}
          <Alert variant="success">
          Consent form successfully submitted. Thank you. <br/>
          Please click on the above logout button to end your session and to complete another form.
          </Alert>
          <Button className="mt-3" onClick={pdfDownload} disabled={processing}>
            Download Consent Form PDF
          </Button>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col xs={12} sm={12} md={8}>
          <Card>
            <Card.Header className="bg-light h4">{`Human Papillomavirus-- HPV Consent form`}</Card.Header>
            {failed && (
              <Card bg="danger" text="light" className="mb-3">
                <Card.Header>Error</Card.Header>
                <Card.Body>
                  {serverError
                    ? serverError.message
                    : 'Error updating patient form record'}
                </Card.Body>
              </Card>
            )}
            </Card>
              <Wizard
                initialValues={{
                  //Patient
                  patientForename: '',
                  patientSurname: '',
                  nHSNumber: '',
                  patientGender: '',
                  patientGenderOther: '',
                  dobMonth: '',
                  dobYear: '',
                  dobDay: '',
                  patientDOB: '',
                  patientAddressLine1: '',
                  patientAddressLine2: '',
                  patientAddressLine3: '',
                  patientAddressLine4: '',
                  patientPostCode: '',
                  childPostCodeSearch: '',
                  parentGuardianConsentPhoneCarer: '',
                  parentGuardianConsentEmailAddress: '',
                  parentGuardianConsentRelationshipForChild: '',
                  parentGuardianConsentRelationshipForChildOther: '',
                  ethnicity: '',
                  yearGroup: '',
                  yearClass: '',

                  //Consent

                  parentGuardianConsentStatus: '',
                  parentGuardianConsentReasonForRefusal: '',
                  parentGuardianConsentReasonForRefusalComments: '',
                  parentGuardianConsentSignatureName: '',
                  parentGuardianConsentSignatureDate: '',
                  parentGuardianConsentSignatureDay: moment().date(),
                  parentGuardianConsentSignatureMonth: 1 + moment().month(),
                  parentGuardianConsentSignatureYear: moment().year(),
                  parentGuardianNeedMoreInfoRequire: '',
                  parentGuardianFurtherInformation: '',
                  q1: '',
                  q1Comment: null,
                  q1VaccineWhere: '',
                  q1VaccineBrand: '',
                  q1VaccineWhen: null,
                  q1VaccineWhenDay: '',
                  q1VaccineWhenMonth: '',
                  q1VaccineWhenYear: '',
                  q2: '',
                  q2Comment: '',
                  q3: '',
                  q3Comment: '',
                  q4: '',
                  q4Comment: '',
                  q5: '',
                  q5Comment: '',
                  q6: null,
                  q6Comment: null,
                  q7: null,
                  q7Comment: null,
                  q8: null,
                  q8Comment: null,
                  q9: null,
                  q9Comment: null,
                }}
                validationSchema={FormValidator.HPV}
                onSubmit={async (values) => {
                  values.vaccineDeliveredCode = userDetail.vaccineCode;
                  values.consentLetterReferenceCode =
                    userDetail.consentLetterReferenceCode;
                  values.schoolCodeURN = userDetail.schoolCode;

                  values.patientDOB = dateConversion(
                    values.dobYear,
                    values.dobMonth,
                    values.dobDay,
                  );
                  values.parentGuardianConsentSignatureDate = dateConversion(
                    values.parentGuardianConsentSignatureYear,
                    values.parentGuardianConsentSignatureMonth,
                    values.parentGuardianConsentSignatureDay,
                  );

                  //Day, month, year are nullable - make sure have all values before date conversion
                  if (
                    props.vaccineQuestions.q1VaccineWhen &&
                    values.q1VaccineWhenYear &&
                    values.q1VaccineWhenMonth &&
                    values.q1VaccineWhenDay
                  ) {
                    values.q1VaccineWhen = dateConversion(
                      values.q1VaccineWhenYear,
                      values.q1VaccineWhenMonth,
                      values.q1VaccineWhenDay,
                    );
                  }
                  setLoading(true);

                  try {
                    const submitConsentFom = await submitHPVConsent(
                      userDetail.token,
                      values,
                    );

                    setLoading(false);
                    setSuccess(true);
                    setFailed(null);
                    setServerError(null);
                    values['inputDate'] = moment(submitConsentFom) // returns the date of input
                      .utc()
                      .format('DD-MM-YYYY HH:mm:ss'); //PDF timestamp
                    setPdfFormValues(values);
                  } catch (error) {
                    setLoading(false);
                    setFailed(true);
                    setServerError(error);
                  }
                }}
              >
                
                <WizardStep validationSchema={FormValidator.demographics}>
                    <Card.Body>
              <Card.Text>
              School-aged children are offered the human papillomavirus vaccination (HPV) via the national school aged immunisation programme. This is now a single dose as this has been found to be as effective.
              </Card.Text>
              <Card.Text>
              The School Aged Immunisation Service (SAIS) offers this vaccine in school in year 8, or in year 9 if missed in year 8.
              </Card.Text>
              <Card.Title>
              Information about the HPV Vaccine.
              </Card.Title>
              <Card.Text>
              HPV vaccine helps protects against HPV related cancers later in life, such as:
              </Card.Text>
              <ListGroup variant="flush">
                <ListGroup.Item>cervical cancer</ListGroup.Item>
                <ListGroup.Item>some mouth and throat cancers</ListGroup.Item>
                <ListGroupItem>some cancers of the anus and genital areas</ListGroupItem>
              </ListGroup>
              <Card.Title style={{textAlign:'center'}}>
              The HPV vaccine does not contain gelatine.
              </Card.Title>
              <Card.Title>Common Side Effects</Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item>Redness/swelling at the injection site, and some people may get a small lump which will usually disappear in a few weeks.</ListGroup.Item>
                <ListGroup.Item>Raised temperature</ListGroup.Item>
                <ListGroupItem>Headache</ListGroupItem>
                <ListGroupItem>Dizziness</ListGroupItem>
                <ListGroupItem>Feeling sick with swollen glands</ListGroupItem>
                <ListGroupItem>More serious side effects are extremely rare and the nurses and trained to deal with these.</ListGroupItem>
              </ListGroup>
              <Card.Text>For more information about the HPV vaccination please visit: </Card.Text>
              <Card.Text>
                <a
                  href="https://www.gov.uk/government/publications/hpv-vaccine-vaccination-guide-leaflet"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.gov.uk/government/publications/hpv-vaccine-vaccination-guide-leaflet
                  </a>
                  </Card.Text>
                  <Card.Text>Children under the age of 16 can consent to their own treatment if they're believed to have enough intelligence, competence and understanding to fully appreciate what's involved in their treatment. 
                    This is known as being Gillick competent.</Card.Text>
                    <Demographics
                      loading={loading}
                      gender={filterGender}
                      YearGroup={filterYearGroup}
                      ethnicity={props.ethnicityOptions}
                    />
                    </Card.Body>
                  </WizardStep>
                  <WizardStep >
                    <DuplicateRecord/>
                </WizardStep>
                  <WizardStep validationSchema={FormValidator.HPV}>
                    <ParentalResponsibility/>
                    <HpvQuestions
                      loading={loading}
                      filterRefusal={filterRefusal}
                      vaccineQuestions={props.vaccineQuestions}
                      filterRelationships={filterRelationships}
                    /> 
                </WizardStep>
              </Wizard>
              </Col>
              </Row>
            );
    }
}