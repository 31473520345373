import PostcodeSearch from '../../../formFields/address/postcodeSearch.js';
import CustomFieldError from '../../../formFields/customFieldError.js';
import SelectFieldEthnicity from '../../../formFields/selectFieldEthnicity.js';
import SelectField from '../../../formFields/selectField.js';
import { Form, Row, Col } from 'react-bootstrap';
import InputField from '../../../formFields/inputField.js';
import { useFormikContext } from 'formik';
import React, { useEffect, useState, useRef } from 'react';

export default function Demographics(props) {
  const { values, errors, touched } = useFormikContext();
  useEffect(() => {
    window.scrollTo(0, 20); // 20 prevent scrolling issues, not firing on build
  }, []);
  return (
    <>
      <InputField
        name="patientForename"
        type="text"
        label="Child Forename*"
        placeholder="Please enter your child's forename"
        disabled={props.loading}
      />
      <InputField
        name="patientSurname"
        type="text"
        label="Child Surname*"
        placeholder="Please enter your child's surname"
        disabled={props.loading}
      />
      <PostcodeSearch
        name="childPostCodeSearch"
        addressFields={'patientAddress'}
        postCodeField={'patient'}
        label={'Child address postcode search'}
        inputLabel={'Child Address'}
        placeholder={'Please enter a valid postcode to search'}
      />
      <Form.Group>
        <Form.Label>Child Date of Birth* (For example 15 3 1984)</Form.Label>
        <CustomFieldError name={'patientDOB'} />
        <Row>
          <Col xs={12} sm={12} md={4}>
            <InputField
              name="dobDay"
              type="text"
              data-testid="dobDay"
              label="Day*"
              placeholder="Child DOB Day"
              disabled={props.loading}
            />
          </Col>
          <Col xs={12} sm={12} md={4}>
            <InputField
              name="dobMonth"
              type="text"
              label="Month*"
              data-testid="dobMonth"
              placeholder="Child DOB Month"
              disabled={props.loading}
            />
          </Col>
          <Col xs={12} sm={12} md={4}>
            <InputField
              name="dobYear"
              type="text"
              label="Year*"
              data-testid="dobYear"
              placeholder="Child DOB Year"
              disabled={props.loading}
            />
          </Col>
        </Row>
      </Form.Group>
      <SelectFieldEthnicity
        name={'ethnicity'}
        label={'Child Ethnicity*'}
        data={props.ethnicity}
        disabled={props.loading}
      />
      <SelectField
        name={'patientGender'}
        label={'Child Gender*'}
        data={props.gender}
        disabled={props.loading}
      />
      {values.patientGender === 'O' &&
        <InputField
        name="patientGenderOther"
        type="text"
        label="Child Gender Other"
        placeholder="Child Gender Other"
        disabled={props.loading || values.patientGender !== 'O'}
      />
      }
      <InputField
        name="parentGuardianConsentPhoneCarer"
        type="text"
        label="Telephone"
        placeholder="Please enter your parent/carer telephone"
        disabled={props.loading}
      />
      <InputField
        name="parentGuardianConsentEmailAddress"
        type="text"
        label="Email"
        placeholder="Please enter your parent/carer email"
        disabled={props.loading}
      />
      <InputField
        name="parentGuardianConsentConfirmEmailAddress"
        type="text"
        label="Confirm Email"
        placeholder="Please confirm your parent/carer email"
        disabled={props.loading}
      />
      <Form.Label>Child NHS Number</Form.Label><br/>
      <Form.Label>(Please find link to find your Child's NHS Number : <a href="https://www.nhs.uk/nhs-services/online-services/find-nhs-number/" target="_blank">https://www.nhs.uk/nhs-services/online-services/find-nhs-number/</a>)</Form.Label>
      <InputField
        name="nHSNumber"
        type="text"
        //label="Child NHS No."
        placeholder="Please enter your child's NHS Number"
        disabled={props.loading}
      />
      <SelectField
        name={'yearGroup'}
        label={'Year Group*'}
        data={props.YearGroup}
        disabled={props.loading}
      />
      <InputField
        name="yearClass"
        type="text"
        label="Year class"
        placeholder="Please enter your child's class"
        disabled={props.loading}
      />
      {Object.keys(touched).length > 0 && Object.keys(errors).length > 0 && (
        <p className="customError mt-4">
          Please complete all required fields (*)
        </p>
      )}
    </>
  );
}
