import * as yup from 'yup';
import { days } from '../utils/daysMonths.js';

export default function nullableDay() {
  return yup
    .string()
    .test('Days', 'Enter a number between 1 and 31 for the day', (value) => {
      if (value) {
        return days.includes(value);
      } else {
        return true; //field is nullable
      }
    });
}
