import CustomFieldError from '../../../formFields/customFieldError.js';
import SelectField from '../../../formFields/selectField.js';
import SelectFieldId from '../../../formFields/selectFieldId.js';
import { Form, Row, Col,Card } from 'react-bootstrap';
import RadioField from '../../../formFields/RadioField.js';
import InputField from '../../../formFields/inputField.js';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';

export default function FluQuestions(props) {
  const { values, errors, touched, handleChange } = useFormikContext();
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 20); // 20 prevent scrolling issues, not firing on build
  }, []);

  return (
    <>
      <Card.Title className="mt-4">
                      {props.vaccineQuestions.confirm}
                    </Card.Title>
                    <Form.Group>
                      <Form.Check.Label className="mb-3">
                        Tick one box below*
                      </Form.Check.Label>
                      <RadioField
                        disabled={props.loading}
                        name="parentGuardianConsentStatus"
                        value="Given"
                        label={'YES I want my child to have the vaccine'}
                        type="radio"
                      />
                      <RadioField
                        disabled={props.loading}
                        name="parentGuardianConsentStatus"
                        value="Not Given"
                        label={'NO I do not want my child to have the vaccine'}
                        type="radio"
                      />
                    </Form.Group>
                     {values.parentGuardianConsentStatus === 'Not Given' &&
                       <SelectFieldId
                       name={'parentGuardianConsentReasonForRefusal'}
                       label={'Reason for refusal*'}
                       data={props.refusal}
                       disabled={props.loading ||
                        values.parentGuardianConsentStatus === 'Given' ||
                        values.parentGuardianConsentStatus === ''}
                     />
                     }      
                     {values.parentGuardianConsentReasonForRefusal === '62' && ( //Had elsewhere
                      <InputField
                        name="parentGuardianConsentReasonForRefusalComments"
                        type="text"
                        label="Please say when and where"
                        placeholder="Please say when and where"
                        disabled={
                          props.loading ||
                          values.parentGuardianConsentStatus === 'Given' ||
                          values.parentGuardianConsentReasonForRefusal !== '62'
                        }
                      />
                    )}
                    {values.parentGuardianConsentStatus === 'Not Given' && 
                    <Form.Group className="mb-3">
                      <Form.Check.Label className="mb-3">
                        Would you like more information?*
                      </Form.Check.Label>
                      <RadioField
                        disabled={
                          props.loading ||
                          values.parentGuardianConsentStatus === 'Given' ||
                          values.parentGuardianConsentStatus === ''
                        }
                        name="parentGuardianNeedMoreInfoRequire"
                        value="Yes"
                        label="Yes"
                        type="radio"
                      />
                      <RadioField
                        disabled={
                          props.loading ||
                          values.parentGuardianConsentStatus === 'Given' ||
                          values.parentGuardianConsentStatus === ''
                        }
                        name="parentGuardianNeedMoreInfoRequire"
                        value="No"
                        label="No"
                        type="radio"
                      />
                    </Form.Group>}
                    <SelectFieldId
                       name={'parentGuardianConsentRelationshipForChild'}
                       label={'Relationship to child*'}
                       data={props.relationships}
                       disabled={props.loading}
                     />
                     {values.parentGuardianConsentRelationshipForChild ==='78' &&
                    <InputField
                      name="parentGuardianConsentRelationshipForChildOther"
                      type="text"
                      label="Relationship to child other"
                      placeholder="Relationship to child other"
                      disabled={
                       props.loading ||
                        values.parentGuardianConsentRelationshipForChild !==
                          '78' //Other
                      }
                    />}
                    <InputField
                      name="parentGuardianConsentSignatureName"
                      type="text"
                      label="Your name*"
                      placeholder="Your name"
                      disabled={props.loading}
                    />
                    <Form.Group>
                      <Form.Label>Submission date</Form.Label>

                      {/* <div className="mb-2">
                        <Button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => setCurrentDate(setFieldValue, false)}
                        >
                          <span>
                            <img
                              className="image-button"
                              src={CalendarIcon}
                              alt="calendar click for todays date"
                            />
                          </span>
                          Today
                        </Button>
                      </div> */}
                      <Row>
                        <Col xs={12} sm={12} md={4}>
                          <InputField
                            name="parentGuardianConsentSignatureDay"
                            type="text"
                            label="Day"
                            value={moment().date()}
                            disabled={true}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={4}>
                          <InputField
                            name="parentGuardianConsentSignatureMonth"
                            type="text"
                            label="Month"
                            value={1 + moment().month()}
                            disabled={true}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={4}>
                          <InputField
                            name="parentGuardianConsentSignatureYear"
                            type="text"
                            label="Year"
                            value={moment().year()}
                            disabled={true}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    
                    <div style={values.parentGuardianConsentStatus==='Given' ? {} : { display: 'none' }} >
                    <hr className="form-divider" />
                    <Card.Title className="mb-4 mt-4">
                      Health Questionnaire
                    </Card.Title>
                    <Form.Group className="form-input-spacing">
                      <Form.Check.Label className="mb-3">
                        {props.vaccineQuestions.q1}
                      </Form.Check.Label>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given'
                        }
                        label="Yes"
                        type="radio"
                        name="q1"
                        data-testid="q1"
                        value={'Yes'}
                        checked={values.q1 === 'Yes'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q1 && errors.q1}
                      ></Form.Check>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given'
                        }
                        type="radio"
                        label="No"
                        name="q1"
                        value={'No'}
                        checked={values.q1 === 'No'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q1 && errors.q1}
                      ></Form.Check>
                      {touched.q1 && errors.q1 && (
                        <p className="radioError">{errors.q1}</p>
                      )}
                    </Form.Group>
                    {values.q1 === 'Yes' &&     
                    <Form.Group
                      controlId="q1Comment"
                      className="form-input-spacing"
                    >
                      <Form.Label>
                        {props.vaccineQuestions.q1Comment}
                      </Form.Label>
                      <Form.Control
                        placeholder={props.vaccineQuestions.q1Comment}
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          values.q1 === 'No' ||
                          values.q1 === ''
                        }
                        type="text"
                        name="q1Comment"
                        data-testid="q1Comment"
                        value={values.q1Comment}
                        onChange={handleChange}
                        isInvalid={touched.q1Comment && errors.q1Comment}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.q1Comment}
                      </Form.Control.Feedback>
                    </Form.Group>}

                    <Form.Group className="form-input-spacing">
                      <Form.Check.Label className="mb-3">
                        {props.vaccineQuestions.q2}
                      </Form.Check.Label>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given'
                        }
                        label="Yes"
                        type="radio"
                        name="q2"
                        value={'Yes'}
                        checked={values.q2 === 'Yes'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q2 && errors.q2}
                      ></Form.Check>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given'
                        }
                        type="radio"
                        label="No"
                        name="q2"
                        value={'No'}
                        checked={values.q2 === 'No'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q2 && errors.q2}
                      ></Form.Check>
                      {touched.q2 && errors.q2 && (
                        <p className="radioError">{errors.q2}</p>
                      )}
                    </Form.Group>
                    {values.q2 === 'Yes' && 
                    <Form.Group
                      controlId="q2Comment"
                      className="form-input-spacing"
                    >
                      <Form.Label>
                        {props.vaccineQuestions.q2Comment}
                      </Form.Label>
                      <Form.Control
                        placeholder={props.vaccineQuestions.q2Comment}
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          values.q2 === 'No' ||
                          values.q2 === ''
                        }
                        type="text"
                        name="q2Comment"
                        value={values.q2Comment}
                        onChange={handleChange}
                        isInvalid={touched.q2Comment && errors.q2Comment}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.q2Comment}
                      </Form.Control.Feedback>
                    </Form.Group>}
                    <Form.Group className="form-input-spacing">
                      <Form.Check.Label className="mb-3">
                        {props.vaccineQuestions.q3}
                      </Form.Check.Label>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given'
                        }
                        label="Yes"
                        type="radio"
                        name="q3"
                        value={'Yes'}
                        checked={values.q3 === 'Yes'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q3 && errors.q3}
                      ></Form.Check>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given'
                        }
                        type="radio"
                        label="No"
                        name="q3"
                        value={'No'}
                        checked={values.q3 === 'No'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q3 && errors.q3}
                      ></Form.Check>
                      {touched.q3 && errors.q3 && (
                        <p className="radioError">{errors.q3}</p>
                      )}
                    </Form.Group>
                    {values.q3 === 'Yes' && 
                    <Form.Group
                      controlId="q3Comment"
                      className="form-input-spacing"
                    >
                      <Form.Label>If yes, please give details</Form.Label>
                      <Form.Control
                        placeholder="If yes, please give details"
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          values.q3 === 'No' ||
                          values.q3 === ''
                        }
                        type="text"
                        name="q3Comment"
                        value={values.q3Comment}
                        onChange={handleChange}
                        isInvalid={touched.q3Comment && errors.q3Comment}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.q3Comment}
                      </Form.Control.Feedback>
                    </Form.Group>}
                    <Form.Group className="form-input-spacing">
                      <Form.Check.Label className="mb-3">
                        {props.vaccineQuestions.q4}
                      </Form.Check.Label>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given'
                        }
                        label="Yes"
                        type="radio"
                        name="q4"
                        value={'Yes'}
                        checked={values.q4 === 'Yes'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q4 && errors.q4}
                      ></Form.Check>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given'
                        }
                        type="radio"
                        label="No"
                        name="q4"
                        value={'No'}
                        checked={values.q4 === 'No'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q4 && errors.q4}
                      ></Form.Check>
                      {touched.q4 && errors.q4 && (
                        <p className="radioError">{errors.q4}</p>
                      )}
                    </Form.Group>
                    {values.q4 === 'Yes' && 
                    <Form.Group
                      controlId="q4Comment"
                      className="form-input-spacing"
                    >
                      <Form.Label>If yes, please give details</Form.Label>
                      <Form.Control
                        placeholder="If yes, please give details"
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          values.q4 === 'No' ||
                          values.q4 === ''
                        }
                        type="text"
                        name="q4Comment"
                        value={values.q4Comment}
                        onChange={handleChange}
                        isInvalid={touched.q4Comment && errors.q4Comment}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.q4Comment}
                      </Form.Control.Feedback>
                    </Form.Group>}
                    <Form.Group className="form-input-spacing">
                      <Form.Check.Label className="mb-3">
                        {props.vaccineQuestions.q5}
                      </Form.Check.Label>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          values.q4 === 'No' ||
                          values.q4 === ''
                        }
                        label="Yes"
                        type="radio"
                        name="q5"
                        value={'Yes'}
                        checked={values.q5 === 'Yes'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q5 && errors.q5}
                      ></Form.Check>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          values.q4 === 'No' ||
                          values.q4 === ''
                        }
                        type="radio"
                        label="No"
                        name="q5"
                        value={'No'}
                        checked={values.q5 === 'No'}
                        onChange={handleChange}
                        isInvalid={touched.q5 && errors.q5}
                      ></Form.Check>
                      {touched.q5 && errors.q5 && (
                        <p className="radioError">{errors.q5}</p>
                      )}
                    </Form.Group>
                    {values.q5 === 'Yes' && 
                    <Form.Group
                      controlId="q5Comment"
                      className="form-input-spacing"
                    >
                      <Form.Label>If yes, please give details</Form.Label>
                      <Form.Control
                        placeholder="If yes, please give details"
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          values.q5 === 'No' ||
                          values.q5 === '' ||
                          values.q4 === 'No' ||
                          values.q4 === ''
                        }
                        type="text"
                        name="q5Comment"
                        value={values.q5Comment}
                        onChange={handleChange}
                        isInvalid={touched.q5Comment && errors.q5Comment}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.q5Comment}
                      </Form.Control.Feedback>
                    </Form.Group>}

                    <Form.Group className="form-input-spacing">
                      <Form.Check.Label className="mb-3">
                        {props.vaccineQuestions.q6}
                      </Form.Check.Label>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given'
                        }
                        label="Yes"
                        type="radio"
                        name="q6"
                        value={'Yes'}
                        checked={values.q6 === 'Yes'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q6 && errors.q6}
                      ></Form.Check>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given'
                        }
                        type="radio"
                        label="No"
                        name="q6"
                        value={'No'}
                        checked={values.q6 === 'No'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q6 && errors.q6}
                      ></Form.Check>
                      {touched.q6 && errors.q6 && (
                        <p className="radioError">{errors.q6}</p>
                      )}
                    </Form.Group>
                    {values.q6 === 'Yes' && 
                    <Form.Group
                      controlId="q6Comment"
                      className="form-input-spacing"
                    >
                      <Form.Label>If yes, please give details</Form.Label>
                      <Form.Control
                        placeholder="If yes, please give details"
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          values.q6 === 'No' ||
                          values.q6 === ''
                        }
                        type="text"
                        name="q6Comment"
                        data-testid="q6Comment"
                        value={values.q6Comment}
                        onChange={handleChange}
                        isInvalid={touched.q6Comment && errors.q6Comment}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.q6Comment}
                      </Form.Control.Feedback>
                    </Form.Group>}

                    <Form.Group className="form-input-spacing">
                      <Form.Check.Label className="mb-3">
                        7. Is anyone in your family currently receiving
                        treatment that severly affects their immune system (e.g.
                        they need to be kept in isolation)?*
                      </Form.Check.Label>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given'
                        }
                        label="Yes"
                        type="radio"
                        name="q7"
                        data-testid="q7"
                        value={'Yes'}
                        checked={values.q7 === 'Yes'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q7 && errors.q7}
                      ></Form.Check>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given'
                        }
                        type="radio"
                        label="No"
                        name="q7"
                        value={'No'}
                        checked={values.q7 === 'No'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q7 && errors.q7}
                      ></Form.Check>
                      {touched.q7 && errors.q7 && (
                        <p className="radioError">{errors.q7}</p>
                      )}
                    </Form.Group>
                    {values.q7 === 'Yes' && 
                    <Form.Group
                      controlId="q7Comment"
                      className="form-input-spacing"
                    >
                      <Form.Label>If yes, please give details</Form.Label>
                      <Form.Control
                        placeholder="If yes, please give details"
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          values.q7 === 'No' ||
                          values.q7 === ''
                        }
                        type="text"
                        name="q7Comment"
                        value={values.q7Comment}
                        onChange={handleChange}
                        isInvalid={touched.q7Comment && errors.q7Comment}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.q7Comment}
                      </Form.Control.Feedback>
                    </Form.Group>}
                    {(values.q1 === 'Yes' ||
                            values.q2 === 'Yes' ||
                            values.q3 === 'Yes' ||
                            values.q4 === 'Yes' ||
                            values.q5 === 'Yes' ||
                            values.q6 === 'Yes' ||
                            values.q7 === 'Yes') && 
                    <Form.Group className="form-input-spacing">
                      <Form.Check.Label
                        className={
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          (values.q1 !== 'Yes' &&
                            values.q2 !== 'Yes' &&
                            values.q3 !== 'Yes' &&
                            values.q4 !== 'Yes' &&
                            values.q5 !== 'Yes' &&
                            values.q6 !== 'Yes' &&
                            values.q7 !== 'Yes')
                            ? 'mb-2 disabled-label'
                            : 'mb-2'
                        }
                      >
                        8. If yes to the above questions can your child avoid
                        close contact with them for two weeks after receiving
                        the vaccination?*
                      </Form.Check.Label>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          (values.q1 !== 'Yes' &&
                            values.q2 !== 'Yes' &&
                            values.q3 !== 'Yes' &&
                            values.q4 !== 'Yes' &&
                            values.q5 !== 'Yes' &&
                            values.q6 !== 'Yes' &&
                            values.q7 !== 'Yes')
                        }
                        label="Yes"
                        type="radio"
                        name="q8"
                        data-testid="q8"
                        value={'Yes'}
                        checked={values.q8 === 'Yes'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q8 && errors.q8}
                      ></Form.Check>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          (values.q1 !== 'Yes' &&
                            values.q2 !== 'Yes' &&
                            values.q3 !== 'Yes' &&
                            values.q4 !== 'Yes' &&
                            values.q5 !== 'Yes' &&
                            values.q6 !== 'Yes' &&
                            values.q7 !== 'Yes')
                        }
                        type="radio"
                        label="No"
                        name="q8"
                        value={'No'}
                        checked={values.q8 === 'No'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q8 && errors.q8}
                      ></Form.Check>
                      {touched.q8 && errors.q8 && (
                        <p className="radioError">{errors.q8}</p>
                      )}
                    </Form.Group>}
                    {((values.q1 === 'Yes' ||
                            values.q2 === 'Yes' ||
                            values.q3 === 'Yes' ||
                            values.q4 === 'Yes' ||
                            values.q5 === 'Yes' ||
                            values.q6 === 'Yes' ||
                            values.q7 === 'Yes') && values.q8 === 'No') &&
                    <Form.Group
                      controlId="q8Comment"
                      className="form-input-spacing"
                    >
                      <Form.Label
                        className={
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          (values.q1 !== 'Yes' &&
                            values.q2 !== 'Yes' &&
                            values.q3 !== 'Yes' &&
                            values.q4 !== 'Yes' &&
                            values.q5 !== 'Yes' &&
                            values.q6 !== 'Yes' &&
                            values.q7 !== 'Yes')
                            ? 'mb-2 disabled-label'
                            : null
                        }
                      >
                        If no, please give details
                      </Form.Label>
                      <Form.Control
                        placeholder="If no, please give details"
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          values.q8 === 'Yes' ||
                          values.q8 === '' ||
                          (values.q1 !== 'Yes' &&
                            values.q2 !== 'Yes' &&
                            values.q3 !== 'Yes' &&
                            values.q4 !== 'Yes' &&
                            values.q5 !== 'Yes' &&
                            values.q6 !== 'Yes' &&
                            values.q7 !== 'Yes')
                        }
                        type="text"
                        name="q8Comment"
                        value={values.q8Comment}
                        onChange={handleChange}
                        isInvalid={touched.q8Comment && errors.q8Comment}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.q8Comment}
                      </Form.Control.Feedback>
                    </Form.Group>}

                    <Form.Group className="form-input-spacing">
                      <Form.Check.Label className="mb-3">
                        9. Does your child take any medications (including
                        salisylate therapy e.g. aspirin?)*
                      </Form.Check.Label>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given'
                        }
                        label="Yes"
                        type="radio"
                        name="q9"
                        value={'Yes'}
                        checked={values.q9 === 'Yes'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q9 && errors.q9}
                      ></Form.Check>
                      <Form.Check
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given'
                        }
                        type="radio"
                        label="No"
                        name="q9"
                        value={'No'}
                        checked={values.q9 === 'No'}
                        onChange={handleChange}
                        aria-required={true}
                        isInvalid={touched.q9 && errors.q9}
                      ></Form.Check>
                      {touched.q9 && errors.q9 && (
                        <p className="radioError">{errors.q9}</p>
                      )}
                    </Form.Group>
                    {values.q9 === 'Yes' &&
                    <Form.Group
                      controlId="q9Comment"
                      className="form-input-spacing"
                    >
                      <Form.Label>If yes, please give details</Form.Label>
                      <Form.Control
                        placeholder="If yes, please give details"
                        disabled={
                         props.loading ||
                          values.parentGuardianConsentStatus === 'Not Given' ||
                          values.q9 === 'No' ||
                          values.q9 === ''
                        }
                        type="text"
                        name="q9Comment"
                        value={values.q9Comment}
                        onChange={handleChange}
                        isInvalid={touched.q9Comment && errors.q9Comment}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.q9Comment}
                      </Form.Control.Feedback>
                    </Form.Group>}

                    <Form.Group
                      controlId="parentGuardianFurtherInformation"
                      className="form-input-spacing"
                    >
                      <Form.Label>
                        Please add any futher information you feel would be
                        useful
                      </Form.Label>
                      <Form.Control
                        placeholder="Please add any futher information"
                        disabled={props.loading}
                        type="text"
                        name="parentGuardianFurtherInformation"
                        value={values.parentGuardianFurtherInformation}
                        onChange={handleChange}
                        isInvalid={
                          touched.parentGuardianFurtherInformation &&
                          errors.parentGuardianFurtherInformation
                        }
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.parentGuardianFurtherInformation}
                      </Form.Control.Feedback>
                    </Form.Group>
                    </div>
                    {/* Checks if there are errors and fields have been touched */}
                    {Object.keys(touched).length > 0 &&
                      Object.keys(errors).length > 0 && (
                        <p className="radioError mt-4">
                          Please complete all required fields (*)
                        </p>
                      )}

    </>
  );
}
