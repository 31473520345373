import React, { useEffect } from 'react';
import { Button, Form, Row, Col, Card, Alert, ListGroup, ListGroupItem, FormGroup } from 'react-bootstrap';
import { useFormikContext } from 'formik';

export default function DuplicateRecord() {
  useEffect(() => {
    window.scrollTo(0, 20); // 20 prevent scrolling issues, not firing on build
  }, []);
  const { values, errors, touched } = useFormikContext();
  localStorage .removeItem('token');
  localStorage.removeItem('vaccineCode');
  localStorage.removeItem('schoolCode');
  localStorage.removeItem('consentLetterReferenceCode');
  return (
    <> 
    <Row>
    <Col xs={12} sm={12} md={8}>
      <Card>
        {/* <Card.Header className="bg-light h4">Immunisation Login</Card.Header> */}
        
          <Card bg="danger" text="light" className="mb-3 mt-3">
            <Card.Header>Error</Card.Header>
            <Card.Body>
               {values.duplicateReason}
            </Card.Body>
          </Card>
        </Card>
        </Col>
        </Row>
    </>
    
  );
}
