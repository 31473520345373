import * as yup from 'yup';
import moment from 'moment';

import {
  stringRequired,
  stringMinMaxRequired,
  postCode,
  nullableString,
  detailsString,
  nullableNhsNo,
  nullableTelephone,
  day,
  month,
  year,
  nullableEmail,
  nullableStringMax,
} from '../fields/index.js';

export default yup.object().shape({
  patientForename: stringMinMaxRequired('Child forename', 1, 255),
  patientSurname: stringMinMaxRequired('Child surname', 1, 255),
  patientAddressLine1: stringMinMaxRequired('Address Line 1', 1, 500),
  patientAddressLine2: stringMinMaxRequired('Address Line 2', 1, 500),
  patientAddressLine3: nullableStringMax('Incorrect format', 500),
  patientAddressLine4: stringMinMaxRequired('Address Line 4', 1, 500),
  patientPostCode: postCode('Postcode'),
  dobDay: day(),
  dobMonth: month(),
  dobYear: year(),
  patientDOB: yup.string().test({
    test: function () {
      const now = moment();
      if (this.parent.dobYear && this.parent.dobMonth && this.parent.dobDay) {
        const dob = moment(
          new Date(
            `${this.parent.dobYear}/${this.parent.dobMonth}/${this.parent.dobDay}`,
          ),
        );
        if (moment().diff(moment(dob), 'years') > 18) {
          return this.createError({
            path: 'patientDOB',
            message: 'Child age must be less than 18 years',
          });
        } else if (moment(dob).isAfter(now)) {
          return this.createError({
            path: 'patientDOB',
            message: 'Child date of birth must be in the past',
          });
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  }),

  patientGender: stringRequired('Gender'),
  patientGenderOther: detailsString(
    'patientGender',
    stringMinMaxRequired('Patient Gender Other', 1, 50),
    'O',
  ),
  ethnicity: stringRequired('Ethnicity'),
  parentGuardianConsentPhoneCarer: nullableTelephone(
    'Telephone number - incorrect format',
  ),
  parentGuardianConsentEmailAddress: nullableEmail('Email'),
  parentGuardianConsentConfirmEmailAddress: yup.string()
      .oneOf([yup.ref('parentGuardianConsentEmailAddress')], 'Email and confirm email does not match'),
  nHSNumber: nullableNhsNo('NHS number'),
  yearGroup: stringMinMaxRequired('Year group', 1, 25),
  yearClass: nullableStringMax('Year class', 25)
  })