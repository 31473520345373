import * as yup from 'yup';

const re = /^(19|20)\d{2}$/;

export default function nullableMonth() {
  return yup.string().test('Year', 'Enter a valid 4 digit year', (value) => {
    const valueClean = value ? value.replace(/\s/g, '') : null;
    if (valueClean) {
      return re.test(valueClean);
    } else {
      return true; //field is nullable
    }
  });
}
