import React, { useContext } from 'react';
import UserContext from '../../context/userContext.js';
import { Redirect, Route, Switch, HashRouter } from 'react-router-dom';
import Login from '../authentication/login';
import ImmunoForm from '../immunoForm/immunoForm';

export default function Routing({ loginProcess }) {
  const userDetail = useContext(UserContext);
  if (userDetail.token) {
    return (
      <Switch>
        <Route path="/form" exact component={ImmunoForm} />
        <Redirect to="/form" />
      </Switch>
    );
  } else if (userDetail.token === null) {
    return <Login loginProcess={loginProcess} />;
  } else {
    return <></>;
  }
}
