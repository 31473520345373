export function mapQuestions(vaccineCode) {
  switch (vaccineCode) {
    case 'Flu Jab':
      return {
        confirm:
          'I have understood the information given to me about the nasal flu vaccination. I confirm I have parental responsibility for this child.',
        q1: '1. Has your child had a flu vaccination since September this year?*',
        q1Comment: 'If yes, please give details',
        q2: ' Does your child have any allergies?*',
        q2Comment: 'If yes, please give details',
        q3: '3. Does your child have a severe egg allergy (requiring intensive care admission)?*',
        q4: '4. Does your child have asthma?*',
        q5: '5. Has your child ever been admitted to intensive care because of their asthma?*',
        q6: '6. Does your child have any serious health conditions or are they receiving treatment that severely weakens their immune system (e.g. treatment for leukaemia?)*',
        q7: '7. Is anyone in your family currently receiving treatment that severly affects their immune system (e.g. they need to be kept in isolation)?*',
        q8: '8. If yes to the above questions can your child avoid close contact with them for two weeks after receiving the vaccination?',
        q9: '9. Does your child take any medications (includin salicylate therapy e.g. aspirin?)*',
      };
    case 'MMR':
      return {
        confirm:
          'I have understood the information given to me about the MMR vaccinations. I confirm I have parental responsibility for this child.',
        q1: '1. Has your child had an MMR vaccine before?*',
        q1Comment: null,
        q1VaccineWhere: 'If yes, where did they have the MMR vaccine?',
        q1VaccineBrand: null,
        q1VaccineWhen: 'If yes, when did they have the MMR vaccine?',
        q2: '2. Has your child ever had a severe allergic reaction or any other problems after other injections/vaccines?*',
        q2Comment: 'If yes, please give details',
        q3: '3. Does your child have any allergies?*',
        q4: '4. Does your child have any serious health problems including prolonged bleeding?*',
        q5: '5. Does your child take any medications?*',
        q6: '6. Has your child had any injections (vaccines) in the last 4 weeks?*',
        q7: null,
        q8: null,
        q9: null,
      };
    case 'HPV':
      return {
        confirm:
          'I have understood the information given to me about the HPV vaccinations. I confirm I have parental responsibility for this child.',
        q1: '1. Has your child had an HPV vaccine before?*',
        q1Comment: null,
        q1VaccineWhere: 'If yes, where did they have the HPV vaccine?*',
        q1VaccineBrand: 'If known, what was the brand of HPV vaccine?',
        q1VaccineWhen: 'If known, when did they have the HPV vaccine?',
        q2: '2. Has your child ever had a severe allergic reaction or any other problems after other injections/vaccines?*',
        q2Comment: 'If yes, please give details',
        q3: '3. Does your child have any allergies?*',
        q4: '4. Does your child have any serious health problems including prolonged bleeding?*',
        q5: '5. Does your child take any medications?*',
        q6: null,
        q7: null,
        q8: null,
        q9: null,
      };
    case 'MenACWY/Td/IPV':
      return {
        title:
          'Diphtheria, tetanus and polio vaccine (Td/IPV) and meningitis vaccine (MenACWY)',
        heading: '', //* Note service amends 14.10.22 * Hardcoded on form Men form due to formatting - CP
        link: 'https://www.gov.uk/government/publications/immunisations-for-young-people',
        linkText: 'Please read the attached guide for more information.',
        q1: '1. Has your child had a diphtheria, tetanus and polio vaccine (Td/IPV) vaccine within the last 5 years?*',
        q1Comment:
          'If yes, when did they have the diphtheria, tetanus and polio vaccine (Td/IPV) vaccine?',
        q2: '2. Has your child had a meningitis vaccine (MenACWY) vaccine since the age of 10?*',
        q2Comment:
          'If yes, when did they have the Meningitis vaccine (MenACWY) vaccine?',
        q3: '3. Has your child ever had a severe allergic reaction or any other problems after other injections/vaccines?*',
        q4: '4. Does your child have any allergies?*',
        q5: '5. Does your child have any serious health problems including prolonged bleeding?*',
        q6: '6. Does your child take any medications?*',
        q7: null,
        q8: null,
        q9: null,
      };
    default:
      throw new Error('Unable to create patient form');
  }
}
