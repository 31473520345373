import { Form, InputGroup } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import InputField from '../inputField.js';
import PostcodeSelect from './postcodeSelect.js';
import { Button } from 'react-bootstrap';
import { useState, useRef } from 'react';
import PostCodeError from './postCodeError.js';
//import { getAddresses } from '../../../utils/sendRequest.js';
import { getAddresses_OS } from '../../../utils/sendRequest.js';

export default function PostcodeSearch({ label, ...props }) {
  const { values } = useFormikContext();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [noResults, setNoResults] = useState(false);
  const [error, setError] = useState();
  const [show, setShow] = useState(true);
  const postcodeSearch = useRef();

  const [state, setState] = useState({
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    postcode: '',
  });

  const addAddressToState = (address) => {
    let dpaDepartmentName = address.DPA.DEPARTMENT_NAME || '';
    let dpaOrganisationName = address.DPA.ORGANISATION_NAME || '';
    let dpaSubBuildingName = address.DPA.SUB_BUILDING_NAME || '';
    let dpaBuildingName = address.DPA.BUILDING_NAME || '';
    let dpaBuildingNumber = address.DPA.BUILDING_NUMBER || '';

    let dpaDependentThoroughfareName =
      address.DPA.DEPENDENT_THOROUGHFARE_NAME || '';
    let dpaThoroughfareName = address.DPA.THOROUGHFARE_NAME || '';
    let dpaDoubleDependentLocality =
      address.DPA.DOUBLE_DEPENDENT_LOCALITY || '';
    let dpaDependentLocality = address.DPA.DEPENDENT_LOCALITY || '';
    let dpaPostTown = address.DPA.POST_TOWN || '';
    let dpaPostcode = address.DPA.POSTCODE || '';
    let dpaPOBoxNumber = address.PO_BOX_NUMBER || '';

    //Dummy state update to refresh page

    setState((prevState) => ({
      ...prevState,
      addressLine1: address.DPA.BUILDING_NUMBER,
      addressLine2: address.DPA.THOROUGHFARE_NAME,
      addressLine3: address.DPA.LOCAL_CUSTODIAN_CODE_DESCRIPTION,
      addressLine4: address.DPA.POST_TOWN,
      postCode: address.DPA.POSTCODE,
    }));

    let arrAddrLine = [dpaOrganisationName, dpaDepartmentName, dpaPOBoxNumber].filter((item) => item !== '');
    let arrPremisesV2 = [dpaBuildingNumber, dpaSubBuildingName, dpaBuildingName].filter(item => item);
    let arrThoroughfareLocalityV2 = [dpaDependentThoroughfareName, dpaThoroughfareName, dpaDoubleDependentLocality, dpaDependentLocality].filter(item => item);
    let strPremisesThoroughfareLocality = '';

    const regex = /(^[1-9]+[a-zA-Z]$)|(^[1-9]+-[1-9]+$)/;

        if (regex.test(dpaSubBuildingName) || regex.test(dpaBuildingName) || dpaBuildingNumber !== '') {

            strPremisesThoroughfareLocality = arrPremisesV2[0] + ' ' + arrThoroughfareLocalityV2[0];

            arrThoroughfareLocalityV2.shift();

            arrPremisesV2.shift();
        }

        arrAddrLine = arrAddrLine.concat(arrPremisesV2);
        arrAddrLine = arrAddrLine.concat(strPremisesThoroughfareLocality);
        arrAddrLine = arrAddrLine.concat(arrThoroughfareLocalityV2);
        arrAddrLine = [...new Set(arrAddrLine)];
        arrAddrLine = arrAddrLine.filter(item => item);

    setState((prevState) => ({
      ...prevState,
      addressLine1: arrAddrLine[0] ? arrAddrLine[0] : '',
      addressLine2: arrAddrLine[1] ? arrAddrLine[1] : '',
      addressLine3: arrAddrLine[2] ? arrAddrLine[1] : '',
      addressLine4: dpaPostTown,
      postCode: dpaPostcode,
    }));

    values[`${props.addressFields}Line1`] = arrAddrLine[0] ? arrAddrLine[0] : '';
    values[`${props.addressFields}Line2`] = arrAddrLine[1] ? arrAddrLine[1] : '';
    values[`${props.addressFields}Line3`] = arrAddrLine[2] ? arrAddrLine[2] : '';
    values[`${props.addressFields}Line4`] = dpaPostTown;
    values[`${props.postCodeField}PostCode`] = dpaPostcode;

    setShow(!show);
  };

  const handlePostCodeSearch = () => {

    setShow(true);
    const re =
      /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/;

    if (re.test(postcodeSearch.current.value)) {
      setLoading(true);
    
      getAddresses_OS(postcodeSearch.current.value)
        .then((res) => {
          if (res.header.totalresults === 0) {
            setNoResults(true);
            setData(null);
            setError(null);
          } else {
            setNoResults(false);
            setData(res.results);
            setError(null);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError('No address results found');
        });
    } else {
      //Fails regex postcode search value invalid
      setError('Please enter a valid postcode');
      setData(null);
    }
  };

  return (
    <>
      <Form.Group>
        <hr className="form-divider" />
        <Form.Label>{label}</Form.Label>
        <InputGroup>
          <Form.Control
            ref={postcodeSearch}
            disabled={loading}
            placeholder={props.placeholder}
          ></Form.Control>
          <Button
            variant={'outline-secondary'}
            disabled={loading}
            type="submit"
            onClick={handlePostCodeSearch}
          >
            Search
          </Button>
        </InputGroup>
      </Form.Group>

      {error && <PostCodeError error={error} />}
      {loading && <p>Loading...</p>}
      {data && data.length && show ? (
        <PostcodeSelect
          name={'AddressSelect'}
          label={'Select Address'}
          data={data}
          addressSelectedCallback={addAddressToState}
        />
      ) : (
        noResults && <PostCodeError error={'No address results found'} />
      )}
      <hr className="form-divider" />
      <Form.Group className="form-input-spacing">
        <InputField
          name={`${props.addressFields}Line1`}
          type="text"
          label={`${props.inputLabel} Line 1*`}
          disabled={loading}
        />
        <InputField
          name={`${props.addressFields}Line2`}
          type="text"
          label={`${props.inputLabel} Line 2*`}
          disabled={loading}
        />
        <InputField
          name={`${props.addressFields}Line3`}
          type="text"
          label={`${props.inputLabel} Line 3`}
          disabled={loading}
        />
        <InputField
          name={`${props.addressFields}Line4`}
          type="text"
          label={`${props.inputLabel} Line 4*`}
          disabled={loading}
        />
        <InputField
          name={`${props.postCodeField}PostCode`}
          type="text"
          label={`${props.inputLabel} Postcode*`}
          disabled={loading}
        />
      </Form.Group>
    </>
  );
}
