import { Form } from 'react-bootstrap';
import { useField } from 'formik';

export default function SelectField({ label, ...props }) {
  const { data } = props;
  const [field, meta, helper] = useField(props);
  const { setValue } = helper;

  return (
    <Form.Group className="form-input-spacing" controlId={props.name}>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        {...field}
        aria-required={true}
        isInvalid={meta.touched && meta.error}
        onChange={(x) =>
          x.target.value !== ''
            ? setValue(x.target.value)
            : setValue('')
        }
        disabled={props.disabled}
      >
        <option value={''}>Please select</option>
        {data.map((item, index) => (
          <option key={index} value={item.CategoriesID}>
            {item.CategoryDesc}
          </option>
        ))}
      </Form.Select>
      {meta.touched && meta.error && (
        <p className="customError">{meta.error}</p>
      )}
    </Form.Group>
  );
}
