import React, { useEffect } from 'react';

export default function ParentalResponsibility(props) {
  useEffect(() => {
    window.scrollTo(0, 20); // 20 prevent scrolling issues, not firing on build
  }, []);
  return (
    <>
    <div>
      <br/>
             <h4>Parental Responsibility</h4>
      <p>
        The Children Act 1989 sets out persons who may have parental
        responsibility. These include:{' '}
      </p>
      <ul>
        <li>The child’s mother.</li>
        <li>
          The child’s father, if he was married to the mother at the time of
          birth.
        </li>
        <li>
          Unmarried fathers, who can acquire parental responsibility in several
          different ways:
          <ul>
            {/* <li>
              For children born before 1 December 2003, unmarried fathers will
              have parental responsibility if they marry the mother of their
              child or obtain a parental responsibility order from the court,
              register a parental responsibility agreement with the court or by
              an application to court (This will end on 1st December 2021, when
              all children born before that date will be 18).
            </li> */}
            <li>
              For children born after 1 December 2003, unmarried fathers will
              have parental responsibility if they register the child’s birth
              jointly with the mother at the time of birth, re-register the
              birth if they are the natural father, marry the mother of their
              child or obtain a parental responsibility order from the court
              register with the court for parental responsibility.
            </li>
          </ul>
        </li>
        <li>The child’s legally appointed guardian.</li>
        <li>
          A person in whose favour the court has made a residence order
          concerning the child.
        </li>
        <li>
          A local authority designated in a care order in respect of the child.
        </li>
        <li>
          A local authority or other authorised person who holds an emergency
          protection order in respect of the child. Section 2(9) of the Children
          Act 1989 states that a person who has parental responsibility for a
          child ‘may arrange for some or all of it to be met by one or more
          persons acting on his or her behalf’. Such a person might choose to do
          this, for example, if a childminder or the staff of a boarding school
          have regular care of their child. As only a person exercising parental
          responsibility can give valid consent, in the event of any doubt then
          specific enquiry should be made. Foster parents do not automatically
          have parental responsibility.
        </li>
      </ul>
      </div>
    </>
  );
}
