import * as yup from 'yup';

const re = /^\d{8,16}$/;

export default function nullableTelephone(label) {
  return yup
    .string()
    .test('Invalid telephone number', (value) => {
      const valueClean = value ? value.replace(/\s/g, '') : null;

      if (
        valueClean === '' ||
        valueClean === undefined ||
        valueClean === null
      ) {
        return true;
      } else {
        if (re.test(valueClean)) {
          return true;
        } else {
          return false;
        }
      }
    })
    .label(label)
    .typeError('Invalid telephone number')
    .nullable();
}
