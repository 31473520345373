import { useEffect,useState } from 'react';
import { Button, Form, Row, Col, Card, Alert, ListGroup, ListGroupItem, FormGroup } from 'react-bootstrap';
import { useContext } from 'react';
import UserContext from '../../../context/userContext.js';
import { submitFluConsent, generatePDF } from '../../../utils/sendRequest.js';
import { pdfReferenceValues } from '../../../utils/pdfReferenceValues.js';
import * as FormValidator from '../../../validation/index.js';
import { dateConversion } from '../../../utils/dateConversion.js';
import Demographics from './subForms/demographics.js';
import FluQuestions from './subForms/fluQuestions.js';
import DuplicateRecord from './subForms/duplicateRecord.js';
import ParentalResponsibility from './subForms/parentalResponsibility.js';
import moment from 'moment';
import Wizard from './wizardForm.js';

export default function Flu(props) {
  
  const userDetail = useContext(UserContext);
  const WizardStep = ({ children }) => children;
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [serverError, setServerError] = useState();
  const [pdfFormValues, setPdfFormValues] = useState();

  useEffect(() => {
    window.scrollTo(0, 20); // 20 prevent scrolling issues, not firing on build
  }, []);

  const filterRelationships = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'PARELAT',
  );

  const filterGender = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'GENDER',
  );

  const filterYearGroup = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'SCHYRSGP',
  );

  const filterRefusal = props.patientOptions.filter(
    (x) => x.CategoryDomainGroup === 'PAREFUSAL',
  );

  const pdfDownload = async () => {
    const pdf = pdfReferenceValues(
      pdfFormValues,
      props.ethnicityOptions,
      filterGender,
      filterYearGroup,
      filterRefusal,
      filterRelationships,
    );

    setProcessing(true);

    try {
      const resPdf = await generatePDF(
        pdf,
        userDetail.token,
        props.vaccineQuestions,
      );

      if (!resPdf) {
        setFailed();
      }

      const file = new Blob([resPdf], { type: 'application/pdf' });
      //IE fix
      // window navigator - detect browser
      //msSaveOrOpenBlob ie method to save a file in a browser
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
        return;
      }

      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = `Imm Consent Form ${pdfFormValues.inputDate}.pdf`;
      link.click();
      setTimeout(() => {
        URL.revokeObjectURL(link);
        setProcessing(false);
        setFailed(null);
      }, 100);
    } catch (error) {
      setProcessing(false);
      setFailed(error);
    }
  };

  if (success) {
    return (
      <Row>
        <Col xs={12} sm={12} md={8}>
          {failed && (
            <Alert variant="danger">Error downloading Consent Form PDF</Alert>
          )}
          <Alert variant="success">
          Consent form successfully submitted. Thank you. <br/>
          Please click on the above logout button to end your session and to complete another form.
          </Alert>
          <Button className="mt-3" onClick={pdfDownload} disabled={processing}>
            Download Consent Form PDF
          </Button>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col xs={12} sm={12} md={8}>
          <Card>
            <Card.Header className="bg-light h4">{`Nasal Flu vaccination Consent form`}</Card.Header>
            {failed && (
              <Card bg="danger" text="light" className="mb-3">
                <Card.Header>Error</Card.Header>
                <Card.Body>
                  {serverError
                    ? serverError.message
                    : 'Error updating patient form record'}
                </Card.Body>
              </Card>
            )}
            
          </Card>
        
        <Wizard
                initialValues={{
                  //Patient
                  patientForename: '',
                  patientSurname: '',
                  nHSNumber: '',
                  patientGender: '',
                  patientGenderOther: '',
                  dobMonth: '',
                  dobYear: '',
                  dobDay: '',
                  patientDOB: '',
                  patientAddressLine1: '',
                  patientAddressLine2: '',
                  patientAddressLine3: '',
                  patientAddressLine4: '',
                  patientPostCode: '',
                  childPostCodeSearch: '',
                  parentGuardianConsentPhoneCarer: '',
                  parentGuardianConsentEmailAddress: '',
                  parentGuardianConsentRelationshipForChild: '',
                  parentGuardianConsentRelationshipForChildOther: '',
                  ethnicity: '',
                  yearGroup: '',
                  yearClass: '',

                  //Consent

                  parentGuardianConsentStatus: '',
                  parentGuardianConsentReasonForRefusal: '',
                  parentGuardianConsentReasonForRefusalComments: '',
                  parentGuardianConsentSignatureName: '',
                  parentGuardianConsentSignatureDate: '',
                  parentGuardianConsentSignatureDay: moment().date(),
                  parentGuardianConsentSignatureMonth: 1 + moment().month(),
                  parentGuardianConsentSignatureYear: moment().year(),
                  parentGuardianNeedMoreInfoRequire: '',
                  parentGuardianFurtherInformation: '',
                  q1: '',
                  q1Comment: '',
                  q1VaccineWhere: null,
                  q1VaccineBrand: null,
                  q1VaccineWhen: null,
                  q2: '',
                  q2Comment: '',
                  q3: '',
                  q3Comment: '',
                  q4: '',
                  q4Comment: '',
                  q5: '',
                  q5Comment: '',
                  q6: '',
                  q6Comment: '',
                  q7: '',
                  q7Comment: '',
                  q8: '',
                  q8Comment: '',
                  q9: '',
                  q9Comment: '',
                }}
                validationSchema={FormValidator.Flu}
                onSubmit={async (values) => {
                  values.vaccineDeliveredCode = userDetail.vaccineCode;
                  values.consentLetterReferenceCode =
                    userDetail.consentLetterReferenceCode;
                  values.schoolCodeURN = userDetail.schoolCode;
                  //values.patientForename = userDetail.patientForename;

                  values.patientDOB = dateConversion(
                    values.dobYear,
                    values.dobMonth,
                    values.dobDay,
                  );

                  values.parentGuardianConsentSignatureDate = dateConversion(
                    values.parentGuardianConsentSignatureYear,
                    values.parentGuardianConsentSignatureMonth,
                    values.parentGuardianConsentSignatureDay,
                  );

                  setLoading(true);

                  try {
                    const submitConsentFom = await submitFluConsent(
                      userDetail.token,
                      values,
                    );

                    setLoading(false);
                    setSuccess(true);
                    setFailed(null);
                    setServerError(null);
                    values['inputDate'] = moment(submitConsentFom) // returns the date of input
                      .utc()
                      .format('DD-MM-YYYY HH:mm:ss'); //PDF timestamp
                    setPdfFormValues(values);
                  } catch (error) {
                    setLoading(false);
                    setFailed(true);
                    setServerError(error);
                  }
                }}
              >
                <WizardStep validationSchema={FormValidator.demographics}>
                <Card.Body>
              <Card.Text>
              Your child is entitled to have the nasal flu vaccine this year.
               The School Aged Immunisation Service (SAIS) offers these vaccines in school.
               Please ask the school for the exact date.
              </Card.Text>
              <Card.Title>It is important you contact the immunisation team if:</Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item>Your child receives this vaccination anywhere else after you return the form to us.</ListGroup.Item>
                <ListGroup.Item>If you change your mind after you fill in the form.</ListGroup.Item>
                <ListGroupItem>Your child becomes wheezy in the three days before the immunisation is due.</ListGroupItem>
                <ListGroup.Item>Your child increases their asthma medication in the two weeks before the immunisation is due.</ListGroup.Item>
                
              </ListGroup>
              <br/>
              <Card.Text>
              <b>Immunisation Team Contact details:
<br/>
Telephone: 0121 466 3410
<br/>
Email : BCHNT.BirminghamImms@nhs.net</b>
              </Card.Text>
              <br/>
              <Card.Title>
              Information about the flu vaccination
              </Card.Title>
              <Card.Text>
              Flu can be a very unpleasant illness in children. 
              Some children may get a very high fever, sometimes they may need to go to hospital for treatment. 
              Serious complications of flu include a painful ear infection, acute bronchitis, and pneumonia.
              </Card.Text>
              <Card.Text>
              The flu vaccine is the best protection we have against this unpredictable virus.
              </Card.Text>
              <Card.Text>
              The flu viruses change every year, so the vaccine is updated. 
              We recommend that your child is vaccinated against flu each year, even if vaccinated last year. 
              The effectiveness of the vaccine will vary from year to year, depending on the match between the strain of flu in circulation and that contained in the vaccine.
              As children with some medical conditions may be more vulnerable to flu, it is especially important that they are vaccinated.
              </Card.Text>
              <Card.Text>
              <b>Children may not be able to have the nasal vaccine if they     </b>
              </Card.Text>
              <ListGroup variant="flush">
                <ListGroup.Item>are currently wheezy or have been wheezy in the past 72 hours 
                  (we can offer an injected flu vaccine to avoid a delay in protection)</ListGroup.Item>
                <ListGroup.Item>have increased their asthma medication in the two weeks before the vaccine is due.</ListGroup.Item>
                <ListGroupItem>have needed intensive care due to asthma or egg allergic anaphylaxis (these children should seek the advice of their specialist and may need to have the nasal vaccine in hospital)</ListGroupItem>
                <ListGroup.Item>have a condition, or are on treatment, that severely weakens their immune system or have someone in their household with a severely weakened immune system.</ListGroup.Item>
                <ListGroup.Item>
                are allergic to any other vaccine components - See the website at www.medicines.org.uk/ emc/product/3296/pil for a list of the vaccine's ingredients.
                </ListGroup.Item>
                
              </ListGroup>
              <br/>
              <Card.Text>
              Children who have been vaccinated with the nasal spray should avoid household contact with people with very severely weakened immune systems for around two weeks following vaccination
              </Card.Text>
              <Card.Text>
                <b><u>Gelatine and Vaccines.</u></b>
              </Card.Text>
              <Card.Text>The nasal vaccine is offered to children as it can be more effective than the injected vaccine. It is easier to administer and considered better at reducing the spread of flu to others who may be more vulnerable to the complications of flu.
              The nasal flu vaccine has a highly processed form of porcine gelatine. The gelatine helps to keep the vaccine viruses stable so that the vaccine gives the best protection against flu.
              </Card.Text>
              <Card.Text>
              The nasal vaccine is offered to children as it can be more effective than the injected vaccine. It is easier to administer and considered better at reducing the spread of flu to others who may be more vulnerable to the complications of flu.
              </Card.Text>
              <Card.Text>
              Your child may need the injectable vaccine if they are at high risk from flu due to medical conditions or treatments and can’t have the nasal flu vaccine. In these cases, the injection can be given in the school session.    
              </Card.Text>
              <Card.Text>
              For those who may not accept the use of porcine gelatine in medical products, an alternative injectable vaccine may be available this year. If you would like your child to receive the injectable flu vaccine, please book an appointment at one of our community clinics.
              </Card.Text>

              <Card.Text>
              Please call 0121 466 3410 or email <a
                  href="mailto:BCHCNT.BirminghamImms@nhs.net"
                  target="_blank"
                >
                 BCHCNT.BirminghamImms@nhs.net </a>
                 to book an appointment.
              </Card.Text>
              <Card.Title>
              Common Side Effects
              </Card.Title>
              <ListGroup variant="flush">
                <ListGroup.Item>Raised temperature</ListGroup.Item>
                <ListGroup.Item>Runny or blocked nose</ListGroup.Item>
                <ListGroupItem>Headache</ListGroupItem>
                <ListGroup.Item>Tiredness</ListGroup.Item>
                <ListGroup.Item>
                Loss of appetite
                </ListGroup.Item>
                <ListGroup.Item>More serious side effects are extremely rare and the nurses and trained to deal with these.</ListGroup.Item>
              </ListGroup>
              <br/>
              <Card.Text>
              For more information about the nasal flu vaccination please visit <a href="https://www.gov.uk/government/publications/flu-vaccination-leaflets-and-posters"
                  target="_blank">https://www.gov.uk/government/publications/flu-vaccination-leaflets-and-posters</a>
              </Card.Text>
              <Card.Text>
              Older children under the age of 16 can consent to their own treatment if they're believed to have enough intelligence, competence and understanding to fully appreciate what's involved in their treatment. This is known as being Gillick competent.
              </Card.Text>
              </Card.Body>
                    <Demographics
                      loading={loading}
                      gender={filterGender}
                      YearGroup={filterYearGroup}
                      ethnicity={props.ethnicityOptions}
                    />
                 </WizardStep> 
                 <WizardStep >
                    <DuplicateRecord/>
                    
                </WizardStep>
                  <WizardStep validationSchema={FormValidator.Flu}>
                    <ParentalResponsibility/>
                    <FluQuestions
                      loading={loading}
                      refusal={filterRefusal}
                      vaccineQuestions={props.vaccineQuestions}
                      relationships={filterRelationships}
                    /> 
                </WizardStep>
              </Wizard>
              </Col>
              </Row>
            );
    }
}
