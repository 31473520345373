import { Form } from 'react-bootstrap';

export default function PostcodeSelect({ label, ...props }) {
  const { data } = props;

  const handleSelect = (event) => {
    if (props.addressSelectedCallback != null) {
      props.addressSelectedCallback(data[event.target.value]);
    }
  };

  return (
    <>
      <Form.Label>{label}</Form.Label>
      <Form.Select htmlSize="10" onChange={handleSelect}>
        {data.map((item, index) => (
          // <option key={index} value={index}>
          //   {item.summaryline}
          // </option>
          <option key={index} value={index} onClick={handleSelect}>
            {item.DPA.ADDRESS}
          </option>
        ))}
      </Form.Select>
    </>
  );
}
