import * as yup from 'yup';
import moment from 'moment';

import {
  stringRequired,
  stringMinMaxRequired,
  postCode,
  nullableString,
  detailsString,
  detailsStringExists,
  nullableNhsNo,
  nullableTelephone,
  nullableEmail,
  day,
  month,
  year,
  nullableStringMax,
} from '../fields/index.js';

export default yup.object().shape({
  
  parentGuardianConsentStatus: stringRequired('Please confirm vaccine'),
  parentGuardianConsentReasonForRefusal: detailsString(
    'parentGuardianConsentStatus',
    stringRequired('Selected NO. Please select reason for refusal'),
    'Not Given',
  ),
  parentGuardianConsentReasonForRefusalComments: yup.string().test({
    message: 'Selected already had elsewhere. Please say when and where',
    test: function (value) {
      if (
        !value &&
        this.parent.parentGuardianConsentReasonForRefusal === '62' &&
        this.parent.parentGuardianConsentStatus === 'Not Given'
      ) {
        return false;
      } else {
        return true; //pass through
      }
    },
  }),
  parentGuardianNeedMoreInfoRequire: yup.string().test({
    message: 'Please indicate if you would you like more information',
    test: function (value) {
      if (!value && this.parent.parentGuardianConsentStatus === 'Not Given') {
        return false;
      } else {
        return true; //pass through
      }
    },
  }),
  parentGuardianConsentRelationshipForChild: stringRequired('Relationship'),
  parentGuardianConsentRelationshipForChildOther: detailsString(
    'parentGuardianConsentRelationshipForChild',
    stringMinMaxRequired('Relationship to child other (Td/IPV)', 1, 100),
    '78', //Other
  ),
  parentGuardianConsentSignatureName: stringMinMaxRequired('Signature', 1, 100),
  parentGuardianConsentSignatureDay: day(),
  parentGuardianConsentSignatureMonth: month(),
  parentGuardianConsentSignatureYear: year(),

  parentGuardianConsentStatusMenACWY: stringRequired('Please confirm vaccine'),

  parentGuardianConsentReasonForRefusalMenACWY: detailsString(
    'parentGuardianConsentStatusMenACWY',
    stringRequired('Selected NO. Please select reason for refusal'),
    'Not Given',
  ),

  parentGuardianConsentReasonForRefusalCommentsMenACWY: yup.string().test({
    message: 'Selected already had elsewhere. Please say when and where',
    test: function (value) {
      if (
        !value &&
        this.parent.parentGuardianConsentReasonForRefusalMenACWY === '62' &&
        this.parent.parentGuardianConsentStatusMenACWY === 'Not Given'
      ) {
        return false; //validate if no value and any of these = given
      } else {
        return true; //pass through
      }
    },
  }),

  parentGuardianConsentSignatureNameMenACWY: stringMinMaxRequired(
    'Signature',
    1,
    500,
  ),
  parentGuardianConsentSignatureDateMenACWYDay: day(),

  parentGuardianConsentSignatureDateMenACWYMonth: month(),

  parentGuardianConsentSignatureDateMenACWYYear: year(),

  parentGuardianNeedMoreInfoRequireMenACWY: yup.string().test({
    message: 'Please indicate if you would you like more information',
    test: function (value) {
      if (
        !value &&
        this.parent.parentGuardianConsentStatusMenACWY === 'Not Given'
      ) {
        return false;
      } else {
        return true; //pass through
      }
    },
  }),
  parentGuardianConsentRelationshipForChildMenACWY:
    stringRequired('Relationship'),

  parentGuardianConsentRelationshipForChildMenACWYOther: detailsString(
    'parentGuardianConsentRelationshipForChildMenACWY',
    stringMinMaxRequired('Relationship to child other (MenACWY)', 1, 100),
    '78', //Other
  ),

  //Health questionnaire

  //MenACWY/Td/IPV - Has your child had a Diphtheria, tetanus and polio vaccine (Td/IPV) vaccine within the last 5 years?*',
  q1: yup.string().test({
    message: 'Q1 is a required field',
    test: function (value) {
      if (
        !value &&
        (this.parent.parentGuardianConsentStatus === 'Given' ||
          this.parent.parentGuardianConsentStatusMenACWY === 'Given')
      ) {
        return false; //validate if no value and any of these = given
      } else {
        return true; //pass through
      }
    },
  }),
  q1Comment: detailsString(
    'q1',
    nullableString('Selected YES. Please provide valid text'),
    'Yes',
  ),

  //MenACWY/Td/IPV - Has your child had a Meningitis vaccine (MenACWY) vaccine before? * be changed to
  // Has your child had a Meningitis vaccine (MenACWY) vaccine since the age of 10?*
  // *',
  q2: yup.string().test({
    message: 'Q2 is a required field',
    test: function (value) {
      if (
        !value &&
        (this.parent.parentGuardianConsentStatus === 'Given' ||
          this.parent.parentGuardianConsentStatusMenACWY === 'Given')
      ) {
        return false; //validate if no value and any of these = given
      } else {
        return true; //pass through
      }
    },
  }),
  q2Comment: detailsString(
    'q2',
    nullableString('Selected YES. Please provide valid text'),
    'Yes',
  ),

  //MenACWY/Td/IPV - Has your child ever had a severe allergic reaction or any other problems after other injections/vaccines?*',
  q3: yup.string().test({
    message: 'Q3 is a required field',
    test: function (value) {
      if (
        !value &&
        (this.parent.parentGuardianConsentStatus === 'Given' ||
          this.parent.parentGuardianConsentStatusMenACWY === 'Given')
      ) {
        return false; //validate if no value and any of these = given
      } else {
        return true; //pass through
      }
    },
  }),
  q3Comment: detailsString(
    'q3',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),

  //MenACWY/Td/IPV - Does your child have any allergies?*',
  q4: yup.string().test({
    message: 'Q4 is a required field',
    test: function (value) {
      if (
        !value &&
        (this.parent.parentGuardianConsentStatus === 'Given' ||
          this.parent.parentGuardianConsentStatusMenACWY === 'Given')
      ) {
        return false; //validate if no value and any of these = given
      } else {
        return true; //pass through
      }
    },
  }),
  q4Comment: detailsString(
    'q4',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),

  //MenACWY/Td/IPV - Does your child have any serious health problems including prolonged bleeding?*',
  q5: yup.string().test({
    message: 'Q5 is a required field',
    test: function (value) {
      if (
        !value &&
        (this.parent.parentGuardianConsentStatus === 'Given' ||
          this.parent.parentGuardianConsentStatusMenACWY === 'Given')
      ) {
        return false; //validate if no value and any of these = given
      } else {
        return true; //pass through
      }
    },
  }),
  q5Comment: detailsString(
    'q5',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),

  //MenACWY/Td/IPV - Does your child take any medications?*'
  q6: yup.string().test({
    message: 'Q6 is a required field',
    test: function (value) {
      if (
        !value &&
        (this.parent.parentGuardianConsentStatus === 'Given' ||
          this.parent.parentGuardianConsentStatusMenACWY === 'Given')
      ) {
        return false; //validate if no value and any of these = given
      } else {
        return true; //pass through
      }
    },
  }),

  q6Comment: detailsString(
    'q6',
    stringRequired('Selected YES. Question details'),
    'Yes',
  ),
  parentGuardianFurtherInformation: nullableString('Incorrect format'),
});
