import { useContext, useState, useEffect } from 'react';
import UserContext from '../../context/userContext.js';
import HPV from './forms/hpv.js';
import MenACWYTdIPV from './forms/MenACWYTdIPV.js';
import Flu from './forms/flu.js';
import MMR from './forms/mmr.js';
import { Row, Col } from 'react-bootstrap';
import { getEthnicities, getPatientOptions } from '../../utils/sendRequest.js';
import ErrorNotification from '../base/ErrorNotification.js';
import { mapQuestions } from '../../utils/mapQuestions.js';
import LoadingSpinner from '../base/loadingSpinner.js';

export default function ImmunoForm() {
  const userDetail = useContext(UserContext);
  const [patientOptions, setPatientOptions] = useState();
  const [ethnicityOptions, setEthnicityOptions] = useState();
  const [serverError, setServerError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [vaccineQuestions, setVaccineQuestions] = useState();

  useEffect(() => {
    async function getFormOptions() {
      try {
        const [ethOptions, patOptions, questionOptions] = await Promise.all([
          getEthnicities(userDetail.token),
          getPatientOptions(userDetail.token),
          mapQuestions(userDetail.vaccineCode),
        ]);
        let patientsOptionsRefused = [
          ...patOptions,
          {
            CategoriesID: '',
            CategoryDesc: 'Please select if refused',
            CategoryDomainGroup: 'PAREFUSAL',
            CategoryValueCode: '',
          },
        ];
        setEthnicityOptions(ethOptions);
        setPatientOptions(patientsOptionsRefused);

        setVaccineQuestions(questionOptions);
        setLoading(false);
      } catch (err) {
        setServerError(err);
        setEthnicityOptions(null);
        setPatientOptions(null);

        setVaccineQuestions(null);
        setLoading(false);
      }
    }
    getFormOptions();
  }, []);

  if (loading) {
    return (
      <Row>
        <Col xs={12} sm={12} md={8}>
          <LoadingSpinner />
        </Col>
      </Row>
    );
  } else {
    if (serverError) {
      return <ErrorNotification alertMessage={serverError.message} />;
    } else {
      if (userDetail.vaccineCode === 'MenACWY/Td/IPV') {
        return (
          <MenACWYTdIPV
            ethnicityOptions={ethnicityOptions}
            patientOptions={patientOptions}
            vaccineQuestions={vaccineQuestions}
          />
        );
      } else if (userDetail.vaccineCode === 'Flu Jab') {
        return (
          <Flu
            ethnicityOptions={ethnicityOptions}
            patientOptions={patientOptions}
            vaccineQuestions={vaccineQuestions}
          />
        );
      } else if (userDetail.vaccineCode === 'MMR') {
        return (
          <MMR
            ethnicityOptions={ethnicityOptions}
            patientOptions={patientOptions}
            vaccineQuestions={vaccineQuestions}
          />
        );
      } else {
        return (
          <HPV
            ethnicityOptions={ethnicityOptions}
            patientOptions={patientOptions}
            vaccineQuestions={vaccineQuestions}
          />
        );
      }
    }
  }
}
