import * as yup from 'yup';
import { months } from '../utils/daysMonths.js';

export default function month() {
  return yup
    .string()
    .test(
      'Months',
      'Enter a number between 1 and 12 for the month',
      (value) => {
        if (value) {
          return months.includes(value);
        }
      },
    );
}
